<div style="display:block;height: 150px;">
    <div *ngIf="loading" class="w-100 d-flex justify-content-center">
        <mat-spinner color="warn"></mat-spinner>
    </div>

    <p *ngIf="!loading && !result" class="w-100 text-center">
        Attenzione, il link utilizzato potrebbe essere corrotto o già utilizzato.</p>

    <p *ngIf="!loading && result" class="w-100 text-center">
        L'indirizzo mail è stato confermato.</p>
</div>