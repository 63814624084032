import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-confirm-order',
  template: '',
  // templateUrl: './confirm-order.component.html',
  // styleUrl: './confirm-order.component.css'
})
export class ConfirmOrderComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private notifier: NotifierService,
  ) { }

  code: string;

  ngOnInit() {
    this.code = this.route.snapshot.params.code;
    this.api.confirmOrder(this.code).subscribe({
      next: (data) => {
        this.notifier.showSuccess('Complimenti', 'Prenotazione avvenuta con successo');
        this.router.navigate(['/prenotazioni']);
      },
      error: (err) => {
        this.notifier.showError('Attenzione', 'Si è verificato un errore imprevisto');
        this.router.navigate(['/']);
      }
    });
  }
}
