import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { FiltersModel } from 'src/app/models/entity.model';
import * as moment from 'moment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrl: './index.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class IndexComponent implements OnInit {
  constructor(private storage: StorageService, private api: ApiService) { }

  userData: UserModel;
  isLogged: boolean = false;
  categories = [];
  mainEvents = [];
  nextEvents = [];
  lastEvents = [];
  lastArticles = [];
  innerWidth: any;
  submenu: boolean = false;
  chi_siamo: boolean = false;
  categories_view: boolean = false;
  mobile: boolean = false;

  ngOnInit() {
    this.userData = this.storage.getUserData();
    this.isLogged = this.userData?.admin != undefined;
    this.loadData();
    this.innerWidth = window.innerWidth;
    // recupera eventuale parametro "mobile" settato solo da app mobile
    this.mobile = this.storage.getMobile() == 'true' ? true : false;
  }
  ngAfterInitView() {
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  loadData() {
    this.getCategories();
    this.getMainEvents();
    this.getNextEvents();
    this.getLastEvents();
    this.getLastNews();
  }
  getCategories() {
    const _entity = 'categories';
    const _fields = ['description', 'base_url'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'articles', operator: '=', value: 0 }
    ];
    const _sort = [{ field: 'sort', direction: 'asc' }];
    const _options = {};
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.categories = data;
      });
  }
  getMainEvents() {
    const _entity = 'events';
    const _fields = ['title', 'description', 'date', 'time', 'main_image', 'base_url',];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 0 },
      { field: 'sponsored', operator: '=', value: 1 },
    ];
    const _sort = [{ field: 'date', direction: 'asc' }];
    const _options = { limit: 3 };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.mainEvents = data;
      });
  }
  getNextEvents() {
    const _entity = 'events';
    const _fields = [
      'title',
      'description',
      'date',
      'time',
      'main_image',
      'base_url',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 0 },
      { field: 'date', operator: '>', value: moment().format('YYYY-MM-DD') },
    ];
    const _sort = [{ field: 'date', direction: 'asc' }];
    const _options = { limit: 4 };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.nextEvents = data;
      });
  }
  getLastEvents() {
    const _entity = 'events';
    const _fields = [
      'title',
      'description',
      'date',
      'time',
      'main_image',
      'base_url',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 0 },
    ];
    const _sort = [{ field: 'id', direction: 'desc' }];
    const _options = { limit: 3 };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.lastEvents = data;
      });
  }
  getLastNews() {
    const _entity = 'articles';
    const _fields = [
      'title',
      'description',
      'creation',
      'main_image',
      'base_url',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
    ];
    const _sort = [{ field: 'id', direction: 'desc' }];
    const _options = { limit: 3 };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.lastArticles = data;
      });
  }
}
