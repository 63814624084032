<div class="container">
    <h1>Cancella il tuo profilo</h1>
    <h4 class="text-danger">
        <b>Operazione irreversibile</b>
    </h4>
    <div class="mt-5">
        <p><b>ATTENZIONE:</b> Proseguendo con questa operazione, <u>i dati di questo profilo verranno eliminati
                definitivamente</u> e in maniera irreversibile.</p>
        <ul>
            <li>
                <p>Non sarà possibile partecipare ad eventuali eventi acquistati e non ancora svolti.</p>
            </li>
            <li>
                <p>Se successivamente si vorrà tornare a usufruire dei servizi offerti, sarà necessario effettuare una
                    nuova registrazione.</p>
            </li>
            <li>
                <p>In caso di nuova registrazione, qualora fosse stata già pagata la quota d'iscrizione, laddove
                    prevista, dovrà essere pagata
                    nuovamente.</p>
            </li>
        </ul>
    </div>
    <div class="mt-5">
        <p>Per procedere, digitare il proprio username e cliccare su "Cancella&nbsp;profilo".</p>
        <input [(ngModel)]="inputUsername" class="form-control" style="max-width: 200px"/>
        <button class="btn btn-primary text-center d-block w-100" (click)="deleteUser()"
            style="background: rgb(253,13,13);width: 100%;max-width: 200px; margin-top: 16px;">Cancella&nbsp;profilo</button>
    </div>
</div>