<div class="container">
    <div class="row mb-2">
        <div class="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Iscrizione</h2>
            <p class="w-lg-50">Iscriviti ora per prenotarti ai prossimi eventi e rimanere aggiornato sulle ultime
                proveniente dal nostro mondo</p>
        </div>
    </div>
</div>
<div class="container position-relative">
    <div class="row d-flex justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card mb-5">
                <div class="card-body p-sm-5">
                    <!-- <form method="post" [formGroup]="form" ngNativeValidate (ngSubmit)="insertUser()"> -->
                    <form method="post" [formGroup]="form" ngNativeValidate (ngSubmit)="insertUser()">
                        <!-- <p style="font-weight: 500">Utenza</p> -->
                        <input class="form-control mb-3" type="text" formControlName="username" placeholder="Username *"
                            required="true" minlength="6" maxlength="45">
                        <!-- required="true" minlength="6" maxlength="45" (keyup)="checkUniqueness('username', $event)"> -->
                        <input class="form-control mb-3" type="email" formControlName="email" placeholder="Email *"
                            autocomplete="new-email" required="true" maxlength="50">
                        <!-- <input class="form-control mb-3" type="password" formControlName="tmp_password"
                            autocomplete="new-password" placeholder="Password *"> -->

                        <div class="input-group mb-3">
                            <input formControlName="tmp_password" class="form-control"
                                [type]="showPassword ? 'text' : 'password'" name="password" autocomplete="new-password"
                                placeholder="Password *" required="true">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                    style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;" type="button"
                                    (click)="showPassword=!showPassword">
                                    <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path
                                            d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                    <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                        <path
                                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                        <path
                                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <!-- <input class="form-control mb-3" type="text" formControlName="fiscal_code"
                            placeholder="Codice Fiscale *" required="true" maxlength="16">
                        <div *ngIf="step2" class="mt-5">
                            <p style="font-weight: 500">Generalità</p>
                            <input class="form-control mb-3" type="text" formControlName="name" placeholder="Nome *"
                                required="true" maxlength="45">
                            <input class="form-control mb-3" type="text" formControlName="surname"
                                placeholder="Cognome *" required="true" maxlength="45">
                            <select class="form-select mb-3" formControlName="id_gender" required="true">
                                <option value="" disabled selected>Sesso *</option>
                                <option *ngFor="let gender of genders" value="{{gender.id}}">{{gender.description}}
                                </option>
                            </select>
                            <input class="form-control mb-3" type="text" formControlName="phone"
                                placeholder="Telefono *" required="true" maxlength="15">
                            <p style="font-weight: 500">Nascita</p>
                            <input class="form-control mb-3" type="date" formControlName="birth_date"
                                placeholder="Data di nascita *" required="true">
                            <input class="form-control mb-3" type="text" formControlName="birth_place"
                                placeholder="Luogo di nascita *" required="true" maxlength="50">
                            <input class="form-control mb-3" type="text" formControlName="birth_province"
                                placeholder="Provincia di nascita *" required="true" maxlength="2">
                            <p style="font-weight: 500">Residenza</p>
                            <input class="form-control mb-3" type="text" formControlName="residence_address"
                                placeholder="Indirizzo di residenza *" required="true" maxlength="50">
                            <input class="form-control mb-3" type="text" formControlName="residence_city"
                                placeholder="Comune di residenza *" required="true" maxlength="50">
                            <input class="form-control mb-3" type="text" formControlName="residence_province"
                                placeholder="Provincia di residenza *" required="true" maxlength="2">
                            <input class="form-control mb-3" type="text" formControlName="residence_cap"
                                placeholder="CAP di residenza *" required="true" maxlength="5">
                            <textarea class="form-control mb-3 mt-5" formControlName="notes"
                                placeholder="Inserisci eventuali note"></textarea>
                        </div> -->
                        <div class="form-check pb-3">
                            <input class="form-check-input" type="checkbox" id="studentCheck" required="false" formControlName="student">
                            <label class="form-check-label" for="formCheck" style="font-size: 14px;">
                                Dichiaro di essere uno studente e di avere meno di 19 anni (non compiuti)
                            </label>
                        </div>
                        <div class="form-check pb-3">
                            <input class="form-check-input" type="checkbox" id="formCheck" required="true">
                            <label class="form-check-label" for="formCheck" style="font-size: 14px;">
                                Accetto <a href="/termini-condizioni">termini e condizioni</a></label>
                        </div>
                        <button class="btn btn-primary d-block w-100" type="submit"
                            style="background: rgb(253,13,13);">Iscriviti</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>