<div class="container" *ngIf="!eventLoaded || !userLoaded">Caricamento... </div>
<div class="container" *ngIf="eventLoaded && userLoaded">
    <h3 class="text-center" style="margin-top: 25px;margin-bottom: 20px;">Riepilogo - Prenotazione</h3>
    <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-6" style="margin-top: 20px;margin-bottom: 20px;padding: 20px;">
            <div style="padding: 15px;border-radius: 15px;border: 1.666667px solid var(--bs-gray-500) ;">
                <h3><strong>{{event['title']}}</strong></h3>
                <p>{{event['date'] | date : 'fullDate'}} - ore {{event['time']*1000 | date: 'HH:mm':'UTC'}}</p>
                <p class="m-0 fw-bold">{{event['place']}}</p>
                <p>{{event['address']}} {{event['city']}} {{event['province']}}
                <p class="text-muted">{{event['description']}}</p>
                <div class="row" style="margin-top: 0px;padding-top: 15px;">
                    <div class="col">Costo dell'evento</div>
                    <div class="col-auto">
                        <p class="text-end" style="font-weight: bold;" *ngIf="isStudent">Gratuito</p>
                        <p class="text-end" style="font-weight: bold;" *ngIf="isPartner && event['price']">Prezzo
                            {{event['price'] | currency : 'EUR'}}</p>
                    </div>
                </div>
                <div *ngIf="!user['registration_payed']" class="row">
                    <div class="col">Quota d'iscrizione (prevista nel primo acquisto)</div>
                    <div class="col-auto">
                        <p class="text-end" style="font-weight: bold;" *ngIf="isStudent">Gratuito</p>
                        <p class="text-end" style="font-weight: bold;" *ngIf="isPartner && event['price']">
                            Prezzo {{15 | currency : 'EUR'}}</p>
                    </div>
                </div>
                <div *ngIf="!user['registration_payed'] && !user['student']" class="row">
                    <div class="col">
                        <p class="text-end" style="font-weight: bold;" *ngIf="isStudent">Gratuito</p>
                        <p class="text-end" style="font-weight: bold;" *ngIf="isPartner && event['price']">
                            Totale {{event['price'] + 15 | currency : 'EUR'}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col" style="margin-top: 20px;margin-bottom: 20px;padding: 20px;">
            <div *ngIf="completedUser">
                <p style="font-weight: bold;">Dati fatturazione - prenotazione</p>
                <p>Nominativo: {{user['name']}} {{user['surname']}}</p>
                <p>Codice fiscale: {{user['fiscal_code']}}</p>
                <p>Indirizzo: {{user['residence_address']}}, {{user['residence_cap']}} {{user['residence_city']}},
                    {{user['residence_province']}}</p>
                <p>Telefono: {{user['phone']}}</p>
                <p>Email: {{user['email']}}</p>
            </div>
            <div *ngIf="!completedUser">
                <p style="font-weight: bold;">Dati fatturazione - prenotazione</p>
                <p>Email: {{user['email']}}</p>
                <form method="post" [formGroup]="form" ngNativeValidate (ngSubmit)="updateUser()">
                    <input class="form-control mb-3" type="text" formControlName="fiscal_code" placeholder="Codice Fiscale *" required="true" maxlength="16">
                    <input class="form-control mb-3" type="text" formControlName="name" placeholder="Nome *" required="true" maxlength="45">
                    <input class="form-control mb-3" type="text" formControlName="surname" placeholder="Cognome *" required="true" maxlength="45">
                    <input class="form-control mb-3" type="text" formControlName="residence_address" placeholder="Indirizzo di residenza *" required="true" maxlength="50">
                    <input class="form-control mb-3" type="text" formControlName="residence_city" placeholder="Comune di residenza *" required="true" maxlength="50">
                    <input class="form-control mb-3" type="text" formControlName="residence_province" placeholder="Provincia di residenza *" required="true" maxlength="2">
                    <input class="form-control mb-3" type="text" formControlName="residence_cap" placeholder="CAP di residenza *" required="true" maxlength="5">
                    <input class="form-control mb-3" type="text" formControlName="phone" placeholder="Telefono *" required="true" maxlength="15">
                    <button class="btn btn-primary d-block w-100" type="submit" style="background: rgb(253,13,13);">Prosegui/Acquista</button>
                </form>
            </div>
        </div>
    </div>
    <div class="text-center" style="width: 100%;margin-top: 10px;margin-bottom: 10px;" *ngIf="completedUser">
        <button (click)="createOrder()" class="btn btn-primary" type="button"
            style="background: rgb(230,6,6);padding: 15px 12px;">Prosegui/Acquista</button>
    </div>
</div>