import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.services';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NotifierService } from 'src/app/services/notifier.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('email') email: ElementRef;

  form: FormGroup;
  recaptchaSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private notifier: NotifierService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.form = this.fb.group({
      email: ['', Validators.required],
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  ngAfterViewInit() {
    this.email.nativeElement.focus();
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  send() {
    const val = this.form.value;

    if (val.email) {
      this.recaptchaSubscription = this.recaptchaV3Service.execute('login').subscribe(
        (token) => {
          this.auth.forgotPassword(val.email, token)
            .subscribe({
              next: (data) => {
                this.notifier.showSuccess('Complimenti', 'Una mail sarà inviata alla mail indicata.');
                this.router.navigate(['/login']);
              },
              error: (err) => {
                this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail + ' - ' + err);
              }
            });
        }
      );

    } else {
      this.notifier.showWarning('Attenzione', 'Form non valida');
    }
  }
}
