<div class="container" style="padding-top: 50px;">
    <h3 class="text-start">CiboFuturo notizie</h3>
    <h5 *ngIf="loadingError">Errore nel caricamento delle news</h5>
    <div *ngIf="articles.length && !loading" class="row">
        <div *ngFor="let article of articles" class="col-12 col-sm-6 col-md-4 col-lg-4" style="padding: 10px;">
            <div class="card_hoverable" (click)="cardLink(article)">
                @if(article.record_type == 'article' && article.external_link == 0) {
                <img src="{{metaInfo[article.id_article]?.image}}"
                    style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                } @else {
                <img src="{{article.main_image}}"
                    style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                }
                <div style="padding: 15px;">
                    @if(article.record_type == 'article' && article.external_link == 0) {
                    <h5><strong [innerHTML]="metaInfo[article.id_article]?.title"></strong></h5>
                    <p style="font-weight: bold;">{{article.creation | date : 'fullDate'}}</p>
                    <p [innerHTML]="metaInfo[article.id_article]?.description"></p>
                    } @else {
                    <h5><strong>{{article.title_article}}</strong></h5>
                    <p style="font-weight: bold;">{{article.creation | date : 'fullDate'}}</p>
                    <p>{{article.description_article}}</p>
                    }
                    <div class="text-end" style="min-width: 100%;">
                        <a class="detail_btn btn btn-primary text-center"
                            *ngIf="article.record_type == 'article' && article.external_link != 0"
                            routerLink="/articolo/{{article.base_url_article}}">Leggi articolo</a>
                        <a class="detail_btn btn btn-primary text-center"
                            *ngIf="article.record_type == 'article' && article.external_link == 0" target="_blank"
                            href="{{article.base_url_article}}">Leggi articolo</a>
                        <a class="detail_btn btn btn-primary text-center" *ngIf="article.record_type == 'event'"
                            routerLink="/evento/{{article.base_url_article}}">Maggiori informazioni</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h5 (click)="moreRecords('articles')" *ngIf="offset+limit<articlesCount && !loading" class="text-center"
        style="text-decoration: underline; margin-top: 10px; cursor: pointer;">Altre news</h5>
    <h5 *ngIf="loading" class="text-center">Caricamento...</h5>
</div>