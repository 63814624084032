import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-cancel-order',
  template: '',
  // templateUrl: './cancel-order.component.html',
  // styleUrl: './cancel-order.component.css'
})
export class CancelOrderComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private notifier: NotifierService,
  ) {}

  code: string;

  ngOnInit() {
    this.code = this.route.snapshot.params.code;
    this.api.cancelOrder(this.code).subscribe({
      next: (data) => {
        this.router.navigate(['/']);
      },
      error: (err) => {
        this.router.navigate(['/']);
      }
    });
  }
}
