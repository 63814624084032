import { EventModel } from "./db.model";

export class OptionModel {
    label: string = ''; // nome dell'entità

    title?: string = ''; // titolo della pagina (label presente solo se stringa non vuota)
    detailTitle?: string = ''; // titolo della pagina di dettaglio (label presente solo se stringa non vuota)
    titleClass?: string = ''; // stringa che viene aggiunta alla classe del titolo

    tableClass?: string = ''; // stringa che viene aggiunta alla classe del titolo

    responsive?: boolean = true; // abilita lo scroll orizzontale

    sorting?: boolean = true; // abilita la possibilità di ordinare tramite header della tabella

    filterAll?: boolean = true; // mostra/nasconde il campo di ricerca libera
    filterHeader?: boolean = true; // attiva/disattiva i filtri per singolo campo nel menu di destra

    pagination?: boolean = true; // abilita la funzionalità di paginazione
    pageSizeOptions?: number[] = [10, 100]; // definisce i possibili valori degli elementi per pagina
    pageSize?: number = 10; // valore di default per gli elementi per pagina

    rowClick?: 'edit' | 'detail' | null = 'detail'; // definisce l'azione del click sulla riga della tabella

    rowActionMenu?: boolean = true; // abilita il menu di riga
    rowActionDetail?: boolean = true; // abilita il link al dettaglio nel menu di riga
    rowActionEdit?: boolean = true; // abilita il link al popup di modifica nel menu di riga

    exportTypes?: ('xlsx' | 'pdf' | 'json' | 'xml' | 'csv')[] = []; // abilita il menu di download e le singole voci

    defaultSortActive?: string; // campo su cui effettuare ordinamento di default
    defaultSortDirection?: ('asc' | 'desc'); // direzione di default per l'ordinamento
    defaultClientFilter?: FiltersModel[]; // set di filtri applicati lato client di default - aggiunti ai filtri laterali
}
export class FormUpSelectModel {
    upField: string; // nome del campo del padre da usare come chiave
    filterKey: string; // nome del campo del figlio da usare come chiave
}
export class FormSelectModel {
    sourceName: string; // sostituire con lista tabelle gestite
    placeholder?: string;
    id: string;// nome del campo da usare come chiave
    label: string;// nome del campo da usare come label all'utente
    filters: FiltersModel[];
    distinct?: boolean;
    filter?: boolean; // abilita l'input per filtrare le occorrenze della select
    upSelectFilter?: FormUpSelectModel; // select collegata usata per filtrare i valori
    upsertId?: string; // nome del campo da usare come chiave per l'export - se null, viene usato 'label'
    idJoin?: string; // chiave da utilizzare per l'insert (utilizzato nelle linked)
}
export class FormFieldModel {
    type: 'text-editor' | 'image' | 'select' | 'hidden' | 'text' | 'textarea' | 'boolean' | 'number' | 'currency' | 'readonly' | 'date' | 'time' | 'week' | 'button' | null;
    name: string;
    defaultValue?: string | number;
    placeholder?: string;
    isRequired?: boolean;
    conditionalRequired?: string; // valorizzato con il name di un altro campo per definire un'obbligatorietà condizionata
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    disabled?: boolean;
    customValidation?: 'codiceFiscale' | null;
    select?: FormSelectModel;
    formSort?: number = 0;
}
export class FieldsModel {
    name: string;
    label: string;
    form?: FormFieldModel;
    format: 'text-editor' | 'image' | 'string' | 'number' | 'currency' | 'date' | 'datetime' | 'time' | 'duration' | 'boolean' | 'hidden' | 'button';
    crud: ('select' | 'insert' | 'update' | 'upsert')[];
    tableSort?: number = 0; // ordinamento dei campi nella tabella,
    show?: boolean = true; // mostra/nasconte le colonne
    group?: string; // utilizzato per raggruppare i dati nelle form e nel detail
    secret?: boolean; // se valorizzato, il campo non viene mai richiesto in select (usato ad esempio per le password)
    fn_click?: string; // mertodo che viene assegnato all'evento click del pulsante
}
export class FiltersModel {
    field: string;
    value: string | number | 'LAST_MONTH';
    operator: "=" | "!=" | ">" | ">=" | "<" | "<=" | "like";
}
export class LinkedModel {
    title: string; // usato per il titolo dell'entità
    sourceName: string; // nome dell'entità collegata (tabella di relazione in caso di "n a m")
    routerLink: string; // url path
    routerId: string; // parametro dell'oggetto da inserire nella url
    idJoin: string; // chiave da confrontare con l'id input della pagina
    fields: FieldsModel[]; // elenco dei campi da mostrare a video per l'entità collegata
    filters: FiltersModel[]; // filtri dell'entità collegata
    select?: FormSelectModel; // entità collegata nel caso di relazione "n a m",
    addFields?: FieldsModel[]; // dettaglio dei campi per l'inserimento libero
    hideOptions?: boolean; // nasconde il menu di riga
}

// usato per una lista di selezione delle entità key-value
export class EntitySelectModel {
    value: EntityModel['sourceName'];
    label: EntityModel['options']['label'];
}

export class EntityModel {
    model: any;
    // model: ( typeof ActivityModel | typeof NetworkModel | typeof SchoolModel | typeof ContractModel | typeof StudentsModel | typeof UsersModel | typeof AssistanceModel | typeof RequestModel );
    sourceName: ('events' | 'articles' | 'users_admin' | 'reviews' | 'transactions');
    fields: FieldsModel[];
    filters: FiltersModel[];
    options: OptionModel;
    linked: LinkedModel[];

    constructor(source: string) {
        // in caso di inizializzazione vuota, restituisco null
        if (source == '') { return null; }

        const activeList: EntityModel = entities[source];

        this.model = activeList.model;
        this.sourceName = activeList.sourceName;
        // inizializzo tutti i campi come visibili, se non definito il valore "show"
        this.fields = activeList.fields.map(f => {
            if (f.show == undefined) {
                f.show = true;
            }
            return f;
        });
        this.filters = activeList.filters;
        this.options = activeList.options;
        this.linked = activeList.linked;
    }

    // private _getActiveList(source: string): EntityModel {
    //     switch (source) {
    //         case 'networks': {
    //             return networkList;
    //         }
    //         case 'schools': {
    //             return schoolList;
    //         }
    //         case 'activities': {
    //             return activityList;
    //         }
    //         case 'contracts': {
    //             return contractList;
    //         }
    //         case 'students': {
    //             return studentList;
    //         }
    //         case 'users': {
    //             return userList;
    //         }
    //         case 'assistances': {
    //             return assistanceList;
    //         }
    //         case 'requests': {
    //             return requestList;
    //         }
    //         case 'absences': {
    //             return absenceList;
    //         }
    //         default: {
    //             return null;
    //         }
    //     }
    // }

    getOptions(): OptionModel {
        return this.options;
    }

    getFields(): FieldsModel[] {
        return this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getLinked(): LinkedModel[] {
        return this.linked;
    }

    getFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(field => {
                return field.name;
            });
    }

    getFieldsGroups(): string[] {
        const list = this.getFields()
            .map(f => f.group);
        return list.filter((value, index) => list.indexOf(value) === index);
    }

    getFormFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.form != null)
            .sort(function (a, b) { return a.form.formSort - b.form.formSort; });
    }

    getAllFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.secret != true)
            .filter(f => f.crud.indexOf('select') > -1
                || (f.crud.indexOf('update') > -1 && f.form != null && f.format != 'button'))
            .map(field => {
                return field.name;
            });
    }

    getAllFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getFormFieldsGroups(): string[] {
        const list = this.getFormFields()
            .map(f => f.group);
        return list.filter((value, index) => list.indexOf(value) === index);
    }

    getGroupFormFields(groupName, crud): FieldsModel[] {
        return this.getFormFields()
            .slice()
            .filter(f => f.form.type != 'hidden')
            // vengono esclusi i campi readoly nel caso di form di insert (quelli generati automaticamente dal sistema)
            .filter(f => f.crud.indexOf(crud) > -1 && !(crud == 'insert' && f.form.type == 'readonly'))
            .filter(f => f.group == groupName);
    }

    getVisibleFields(): FieldsModel[] {
        return this.fields
            .slice()
            .filter(f => f.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; });
    }

    getGroupFields(groupName): FieldsModel[] {
        return this.getVisibleFields()
            .filter(f => f.group == groupName);
    }

    getVisibleFieldsList(): string[] {
        return this.fields
            .slice()
            .filter(field => field.format != 'hidden')
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .map(f => f.name);
    }

    getVisibleFieldsKV(): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.fields
            .slice()
            .filter(f => f.crud.indexOf('select') > -1)
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getLinkedVisibleFieldsKV(sourceName: string): FieldsModel[] {
        let output: FieldsModel[] = [];
        this.linked
            .slice()
            .filter(l => l.sourceName == sourceName)
            .map(f => { return f.fields })[0]
            .sort(function (a, b) { return a.tableSort - b.tableSort; })
            .forEach(field => {
                // trasposizione chiave-valore della lista dei campi
                output[field.name] = field;
            });
        return output;
    }

    getFormSelectList(): string[] {
        return this.fields
            .slice()
            .filter(f => f.form?.type == 'select')
            .filter(f => f.crud.indexOf('insert') > -1 || f.crud.indexOf('update') > -1)
            .map(field => {
                return field.name;
            });
    }

    getFormSelectByFieldName(fieldName: string): FormFieldModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)
            .map(field => {
                return field.form;
            })[0];
    }

    getUpsertEntites(): EntitySelectModel[] {
        let upsertEntitiesList: EntitySelectModel[] = [];
        // usa Object.values() perché la chiave della lista è non numerica
        Object.values(entities).forEach(entity => {
            let isUpsertEntity = false;
            entity.fields.forEach(field => {
                if (field.crud.indexOf('upsert') != -1) {
                    // trovato campo con upsert
                    isUpsertEntity = true;
                }
            });
            if (isUpsertEntity) {
                // ha trovato almeno un campo con upsert
                upsertEntitiesList.push({ 'value': entity.sourceName, 'label': entity.options.label });
            }
        });
        return upsertEntitiesList;
    }

    getUpsertFieldsByEntityName(entityName: EntityModel["sourceName"]): FieldsModel[] {
        return entities[entityName].fields.slice()
            .filter(f => f.crud.indexOf('upsert') != -1)
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }

    getUpdateFieldsByEntityName(entityName: EntityModel["sourceName"]): FieldsModel[] {
        return entities[entityName].fields.slice()
            .filter(f => f.form?.type != 'hidden')
            .filter(f => f.crud.indexOf('update') != -1)
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }

    getUpdateRequiredFieldsByEntityName(entityName: EntityModel["sourceName"]): FieldsModel[] {
        return entities[entityName].fields.slice()
            .filter(f => f.form?.type != 'hidden')
            .filter(f => f.crud.indexOf('update') != -1)
            .filter(f => f.form.isRequired)
            .sort(function (a, b) { return a.label.localeCompare(b.label); });
    }

    getRequiredConditionsNames(): string[] {
        var output = [];
        this.fields
            .slice()
            .filter(f => f.form?.conditionalRequired?.length > 0)
            .forEach(f => {
                if (output[f.form.conditionalRequired] == undefined) {
                    output[f.form.conditionalRequired] = [];
                }
                output[f.form.conditionalRequired].push(f.form.name);
            });

        return output;
    }

    getFieldFormFormat(fieldName: string): FormFieldModel["type"] {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)
            .map(field => {
                return field.form?.type;
            })[0];
    }

    getFieldByName(fieldName: string): FieldsModel {
        return this.fields
            .slice()
            .filter(f => f.name == fieldName)[0];
    }
}



const eventsList = new EntityModel('');
eventsList.model = typeof EventModel;
eventsList.sourceName = 'events';
eventsList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    {
        name: 'id_user', label: 'Utente', form: {
            type: 'select',
            name: 'id_user',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un utente',
                id: 'id',
                label: 'fullname',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo utenti attivi
            },
            formSort: 1
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'fullname_user', label: 'Utente', format: 'string', tableSort: 1, crud: ['select'] },
    {
        name: 'title', label: 'Titolo', format: 'string', tableSort: 2, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'title', placeholder: 'Inserisci il titolo dell\'evento', isRequired: true, maxLength: 150, formSort: 2 }
    },
    {
        name: 'description', label: 'Descrizione breve', format: 'string', tableSort: 3, group: 'Descrizione', crud: ['select', 'insert', 'update'],
        form: { type: 'textarea', name: 'description', placeholder: 'Inserisci una descrizione', isRequired: true, maxLength: 200, formSort: 3 }
    },
    {
        name: 'available_seats', label: 'Posti disponibili', group: 'Disponibilità', format: 'number', tableSort: 4, crud: ["select", "insert", "update"],
        form: { name: 'available_seats', type: 'number', isRequired: true, formSort: 4 }
    },
    {
        name: 'reserved_student_seats', label: 'Posti riservati studenti', group: 'Disponibilità', format: 'number', tableSort: 4, crud: ["select", "insert", "update"],
        form: { name: 'reserved_student_seats', type: 'number', isRequired: false, formSort: 4 }
    },
    {
        name: 'reserved_partner_seats', label: 'Posti riservati soci', group: 'Disponibilità', format: 'number', tableSort: 4, crud: ["select", "insert", "update"],
        form: { name: 'reserved_partner_seats', type: 'number', isRequired: false, formSort: 4 }
    },
    {
        name: 'count_users', label: 'Utenti registrati', group: 'Disponibilità', format: 'number', tableSort: 4, crud: ["select"]
    },
    {
        name: 'count_students', label: 'Studenti registrati', group: 'Disponibilità', format: 'number', tableSort: 4, crud: ["select"]
    },
    {
        name: 'count_partners', label: 'Soci registrati', group: 'Disponibilità', format: 'number', tableSort: 4, crud: ["select"]
    },
    {
        name: 'price', label: 'Prezzo', group: 'Evento', format: 'currency', tableSort: 5, crud: ["select", "insert", "update"],
        form: { name: 'price', type: 'currency', isRequired: true, formSort: 5 }
    },
    {
        name: 'main_image', label: 'Immagine principale', format: 'image', tableSort: 6, crud: ['select', 'insert', 'update'],
        form: { type: 'image', name: 'main_image', placeholder: 'Inserisci l\'immagine principale', isRequired: true, maxLength: 100, formSort: 6 }
    },
    // {
    //     name: 'small_image', label: 'Immagine miniatura', format: 'image', tableSort: 7, crud: ['select', 'insert', 'update'],
    //     form: { type: 'image', name: 'small_image', placeholder: 'Inserisci l\'immagine miniatura', isRequired: true, maxLength: 100, formSort: 7 }
    // },
    {
        name: 'body', label: 'Descrizione estesa', format: 'text-editor', tableSort: 8, group: 'Descrizione', crud: ['select', 'insert', 'update'],
        form: { type: 'text-editor', name: 'body', placeholder: 'Inserisci la descrizione', isRequired: true, maxLength: 15000, formSort: 8 }
    },
    {
        name: 'date', label: 'Data', group: 'Data e ora', format: 'date', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'date', name: 'date', placeholder: 'Inserisci la data', isRequired: true, formSort: 9 }
    },
    {
        name: 'time', label: 'Orario', group: 'Data e ora', format: 'time', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'time', name: 'time', placeholder: 'Inserisci l\'orario', isRequired: true, formSort: 9 }
    },
    {
        name: 'place', label: 'Luogo', group: 'Località', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'place', placeholder: 'Inserisci una descrizione', isRequired: true, formSort: 9, maxLength: 100 }
    },
    {
        name: 'address', label: 'Indirizzo', group: 'Località', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'address', placeholder: 'Inserisci un indirizzo', isRequired: false, formSort: 9, maxLength: 50 }
    },
    {
        name: 'city', label: 'Città', group: 'Località', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'city', placeholder: 'Inserisci un indirizzo', isRequired: false, formSort: 9, maxLength: 50 }
    },
    {
        name: 'province', label: 'Provincia', group: 'Località', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'province', placeholder: 'Inserisci una provincia', isRequired: false, formSort: 9, maxLength: 2 }
    },
    {
        name: 'lat', label: 'Latitudine', group: 'Località', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'number', name: 'lat', placeholder: 'Inserisci la latitudine', isRequired: false, formSort: 9 }
    },
    {
        name: 'lng', label: 'Longitudine', group: 'Località', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'number', name: 'lng', placeholder: 'Inserisci la longitudine', isRequired: false, formSort: 9 }
    },
    {
        name: 'closed', label: 'Chiuso', format: 'boolean', crud: ['select', 'insert', 'update'], tableSort: 10,
        form: { type: 'boolean', name: 'closed', isRequired: false, formSort: 10 }
    },
    {
        name: 'base_url', label: 'Link URL', format: 'string', tableSort: 11, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'base_url', placeholder: 'Inserisci il link URL', isRequired: true, maxLength: 200, formSort: 11 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 12, show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', crud: ['select'], tableSort: 13, show: false },
    {
        name: 'active', label: 'Attiva', format: 'boolean', crud: ['select', 'update'], tableSort: 14,
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 14 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
eventsList.filters = [
    //{field:'active', value:1, operator:'='} // solo reti attive
];
eventsList.options = {
    label: 'Eventi',
    title: 'Elenco eventi',
    detailTitle: 'Evento',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: false,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 25,
    rowClick: 'detail',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    // exportTypes: ["xlsx"],
    defaultSortActive: 'date',
    defaultSortDirection: 'asc',
    defaultClientFilter: [
        { field: 'active', operator: '=', value: '1' },
        { field: 'closed', operator: '=', value: '0' }
    ]
};
eventsList.linked = [
    {
        title: 'Categorie', sourceName: 'categories_events', idJoin: 'id_event', routerLink: 'eventi', routerId: 'base_url_category',
        fields: [
            { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_event', label: 'Id Evento', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_category', label: 'Id Categoria', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'description_category', label: 'Categoria', form: null, crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'base_url_category', label: 'Link Categoria', form: null, crud: ['select'], format: 'string', tableSort: 2 },
            { name: 'active', label: 'Categoria attiva', form: null, crud: ['select'], format: 'boolean', tableSort: 3 }
        ],
        filters: [
            { field: 'active', value: 1, operator: '=' },
            { field: 'record_type', value: 'event', operator: '=' },
            // gestione del caso di evento senza categoria
            { field: 'id_category', operator: '>', value: 0 },
        ],
        select: {
            sourceName: 'categories',
            placeholder: 'Selezionare una categoria',
            id: 'id',
            idJoin: 'id_category',
            label: 'description',
            filters: [{ field: 'active', value: 1, operator: '=' }] // solo categorie attive
        }
    },
    {
        title: 'Recensioni', sourceName: 'reviews', idJoin: 'id_event', routerLink: 'evento', routerId: 'base_url',
        fields: [
            { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'title', label: 'Titolo', form: null, crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'body', label: 'Descrizione', form: null, crud: ['select'], format: 'string', tableSort: 2 },
            { name: 'approved', label: 'Approvata', form: null, crud: ['select'], format: 'boolean', tableSort: 3 },
            { name: 'active', label: 'Attiva', form: null, crud: ['select'], format: 'boolean', tableSort: 4 }
        ],
        filters: [{ field: 'active', value: 1, operator: '=' }]
    },
    {
        title: 'Iscritti', sourceName: 'events_users', idJoin: 'id_event', routerLink: 'gestione-utenti', routerId: '',
        fields: [
            { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'fullname', label: 'Nominativo', form: null, crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'student', label: 'Studente', form: null, crud: ['select'], format: 'boolean', tableSort: 2 },
            { name: 'pay_method', label: 'Metodo del pagamento', form: null, crud: ['select'], format: 'string', tableSort: 3 },
            { name: 'amount_paid', label: 'Importo pagato', form: null, crud: ['select'], format: 'currency', tableSort: 4 },
            { name: 'time_paid', label: 'Data pagamento', form: null, crud: ['select'], format: 'date', tableSort: 5 },
        ],
        filters: [
            { field: 'active', value: 1, operator: '=' },
            { field: 'articles', value: 0, operator: '=' }
        ],
        hideOptions: true
    }
];

const articlesList = new EntityModel('');
articlesList.model = typeof EventModel;
articlesList.sourceName = 'articles';
articlesList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    {
        name: 'id_user', label: 'Utente', form: {
            type: 'select',
            name: 'id_user',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un utente',
                id: 'id',
                label: 'fullname',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo utenti attivi
            },
            formSort: 1
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'fullname_user', label: 'Utente', format: 'string', tableSort: 1, crud: ['select'] },
    {
        name: 'title', label: 'Titolo', format: 'string', tableSort: 3, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'title', placeholder: 'Inserisci il titolo dell\'evento', maxLength: 150, formSort: 3, conditionalRequired: 'external_link' }
    },
    {
        name: 'description', label: 'Descrizione breve', format: 'string', tableSort: 4, group: 'Descrizione', crud: ['select', 'insert', 'update'],
        form: { type: 'textarea', name: 'description', placeholder: 'Inserisci una descrizione', maxLength: 200, formSort: 4, conditionalRequired: 'external_link' }
    },
    {
        name: 'main_image', label: 'Immagine principale', format: 'image', tableSort: 5, crud: ['select', 'insert', 'update'],
        form: { type: 'image', name: 'main_image', placeholder: 'Inserisci l\'immagine principale', maxLength: 100, formSort: 5, conditionalRequired: 'external_link' }
    },
    // {
    //     name: 'small_image', label: 'Immagine miniatura', format: 'image', tableSort: 6, crud: ['select', 'insert', 'update'],
    //     form: { type: 'image', name: 'small_image', placeholder: 'Inserisci l\'immagine miniatura', isRequired: true, maxLength: 100, formSort: 6 }
    // },
    {
        name: 'body', label: 'Descrizione estesa', format: 'text-editor', tableSort: 7, group: 'Descrizione', crud: ['select', 'insert', 'update'],
        form: { type: 'text-editor', name: 'body', placeholder: 'Inserisci la descrizione', maxLength: 15000, formSort: 7, conditionalRequired: 'external_link' }
    },
    {
        name: 'base_url', label: 'Link URL', format: 'string', tableSort: 11, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'base_url', placeholder: 'Inserisci il link URL', isRequired: true, maxLength: 1000, formSort: 11 }
    },
    {
        name: 'external_link', label: 'Link locale', format: 'boolean', crud: ['select', 'insert', 'update'], tableSort: 11,
        form: { type: 'boolean', name: 'external_link', isRequired: false, formSort: 11, defaultValue: 1 }
    },
    {
        name: 'btn_check_link', format: 'button', label: 'Verifica URL', crud: ['insert', 'update'], fn_click: 'check_url',
        form: { name: 'btn_check_link', type: 'button', formSort: 11 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 12, show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', crud: ['select'], tableSort: 13, show: false },
    {
        name: 'active', label: 'Attiva', format: 'boolean', crud: ['select', 'update'], tableSort: 14,
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 14 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
articlesList.filters = [
    //{field:'active', value:1, operator:'='} // solo reti attive
];
articlesList.options = {
    label: 'Articoli',
    title: 'Elenco articoli',
    detailTitle: 'Articolo',
    sorting: true,
    filterAll: true,
    filterHeader: false,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 25,
    rowClick: 'detail',
    rowActionMenu: true,
    rowActionDetail: true,
    rowActionEdit: true,
    // exportTypes: ["xlsx"],
    defaultSortActive: 'creation',
    defaultSortDirection: 'asc',
    defaultClientFilter: [
        { field: 'active', operator: '=', value: '1' }
    ]
};
articlesList.linked = [
    {
        title: 'Categorie', sourceName: 'categories_articles', idJoin: 'id_article', routerLink: 'articoli', routerId: 'base_url_category',
        fields: [
            { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_article', label: 'Id News', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'id_category', label: 'Id Categoria', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
            { name: 'description_category', label: 'Categoria', form: null, crud: ['select'], format: 'string', tableSort: 1 },
            { name: 'base_url_category', label: 'Link Categoria', form: null, crud: ['select'], format: 'string', tableSort: 2 },
            { name: 'active', label: 'Categoria attiva', form: null, crud: ['select'], format: 'boolean', tableSort: 3 }
        ],
        filters: [
            { field: 'active', value: 1, operator: '=' },
            { field: 'record_type', value: 'article', operator: '=' },
            // gestione del caso di evento senza categoria
            { field: 'id_category', operator: '>', value: 0 },
        ],
        select: {
            sourceName: 'categories',
            placeholder: 'Selezionare una categoria',
            id: 'id',
            idJoin: 'id_category',
            label: 'description',
            filters: [
                { field: 'active', value: 1, operator: '=' },
                { field: 'articles', value: 1, operator: '=' }
            ]
        }
    },
];

const reviewsList = new EntityModel('');
reviewsList.model = typeof EventModel;
reviewsList.sourceName = 'reviews';
reviewsList.fields = [
    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    {
        name: 'id_user', label: 'Utente', form: {
            type: 'select',
            name: 'id_user',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'users',
                placeholder: 'Selezionare un utente',
                id: 'id',
                label: 'fullname',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo utenti attivi
            },
            formSort: 1
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'fullname_user', label: 'Utente', format: 'string', tableSort: 1, crud: ['select'] },
    {
        name: 'id_event', label: 'Evento', form: {
            type: 'select',
            name: 'id_event',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'events',
                placeholder: 'Selezionare un evento',
                id: 'id',
                label: 'title',
                filters: [{ field: 'active', value: 1, operator: '=' }] // solo eventi attivi
            },
            formSort: 1
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    {
        name: 'title_event', label: 'Evento', format: 'string', tableSort: 2, crud: ['select']
    },
    {
        name: 'title', label: 'Titolo', format: 'string', tableSort: 3, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'title', placeholder: 'Inserisci un titolo', isRequired: true, maxLength: 150, formSort: 3 }
    },
    {
        name: 'body', label: 'Descrizione estesa', format: 'string', tableSort: 7, group: 'Descrizione', crud: ['select', 'insert', 'update'],
        form: { type: 'textarea', name: 'body', placeholder: 'Inserisci la descrizione', isRequired: true, maxLength: 1500, formSort: 7 }
    },
    {
        name: 'approved', label: 'Approvato', format: 'boolean', crud: ['select', 'insert', 'update'], tableSort: 8,
        form: { type: 'boolean', name: 'approved', isRequired: false, formSort: 8 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', crud: ['select'], tableSort: 12, show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', crud: ['select'], tableSort: 13, show: false },
    {
        name: 'active', label: 'Attiva', format: 'boolean', crud: ['select', 'update'], tableSort: 14,
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 14 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
reviewsList.filters = [
    //{field:'active', value:1, operator:'='} // solo reti attive
];
reviewsList.options = {
    label: 'Recensioni',
    title: 'Elenco recensioni',
    detailTitle: 'Recensione',
    sorting: true,
    filterAll: true,
    filterHeader: false,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 25,
    rowClick: 'edit',
    rowActionMenu: true,
    rowActionDetail: false,
    rowActionEdit: true,
    // exportTypes: ["xlsx"],
    defaultSortActive: 'creation',
    defaultSortDirection: 'asc',
    defaultClientFilter: [
        { field: 'active', operator: '=', value: '1' }
    ]
};
reviewsList.linked = [
];

const usersList = new EntityModel('');
usersList.model = typeof EventModel;
usersList.sourceName = 'users_admin';
usersList.fields = [

    { name: 'id', label: 'ID', form: { type: 'hidden', name: 'id', pattern: "^[0-9]*$" }, format: 'hidden', crud: ['select', 'update'] },
    {
        name: 'username', label: 'Username', format: 'string', tableSort: 2, group: 'Utenza', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'username', placeholder: 'Inserisci la username', isRequired: true, maxLength: 45, formSort: 2 }
    },
    {
        name: 'name', label: 'Nome', format: 'string', tableSort: 3, group: 'Generalità', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'name', placeholder: 'Inserisci la username', isRequired: true, maxLength: 45, formSort: 3 }
    },
    {
        name: 'surname', label: 'Cognome', format: 'string', tableSort: 4, group: 'Generalità', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'surname', placeholder: 'Inserisci la username', isRequired: true, maxLength: 45, formSort: 4 }
    },
    {
        name: 'id_gender', label: 'Sesso', group: 'Generalità', form: {
            type: 'select',
            name: 'id_gender',
            isRequired: true,
            pattern: "^[0-9]*$",
            select: {
                sourceName: 'genders',
                placeholder: 'Selezionare un sesso',
                id: 'id',
                label: 'description',
                filters: [{ field: 'active', value: 1, operator: '=' }]
            },
            formSort: 5
        }, format: 'hidden', crud: ['select', 'insert', 'update']
    },
    { name: 'description_gender', label: 'Sesso', format: 'string', tableSort: 5, group: 'Generalità', crud: ['select'] },
    {
        name: 'birth_date', label: 'Data di nascita', format: 'date', tableSort: 6, group: 'Nascita', crud: ['select', 'insert', 'update'],
        form: { type: 'date', name: 'birth_date', placeholder: 'Inserisci la data di nascita', isRequired: true, maxLength: 10, formSort: 6 }
    },
    {
        name: 'birth_place', label: 'Luogo di nascita', format: 'string', tableSort: 7, group: 'Nascita', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'birth_place', placeholder: 'Inserisci il luogo di nascita', isRequired: true, maxLength: 50, formSort: 7 }
    },
    {
        name: 'birth_province', label: 'Provincia di nascita', format: 'string', tableSort: 8, group: 'Nascita', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'birth_province', placeholder: 'Inserisci la provincia di nascita', isRequired: true, maxLength: 2, formSort: 8 }
    },
    {
        name: 'residence_address', label: 'Indirizzo di residenza', group: 'Residenza', format: 'string', tableSort: 9, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_address', placeholder: 'Inserisci un indirizzo', isRequired: true, formSort: 9, maxLength: 50 }
    },
    {
        name: 'residence_city', label: 'Città di residenza', group: 'Residenza', format: 'string', tableSort: 10, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_city', placeholder: 'Inserisci una città', isRequired: true, formSort: 10, maxLength: 50 }
    },
    {
        name: 'residence_province', label: 'Provincia di residenza', group: 'Residenza', format: 'string', tableSort: 11, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_province', placeholder: 'Inserisci una provincia', isRequired: true, formSort: 11, maxLength: 2 }
    },
    {
        name: 'residence_cap', label: 'CAP di residenza', group: 'Residenza', format: 'string', tableSort: 12, crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'residence_cap', placeholder: 'Inserisci un CAP', isRequired: true, formSort: 12, maxLength: 5 }
    },
    {
        name: 'fiscal_code', label: 'Codice fiscale', format: 'string', tableSort: 13, group: 'Generalità', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'fiscal_code', placeholder: 'Inserisci il codice fiscale', isRequired: true, formSort: 13, maxLength: 20 }
    },
    {
        name: 'phone', label: 'Telefono', format: 'string', tableSort: 14, group: 'Generalità', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'phone', placeholder: 'Inserisci il telefono', isRequired: true, formSort: 14, maxLength: 15 }
    },
    {
        name: 'email', label: 'Email', format: 'string', tableSort: 15, group: 'Utenza', crud: ['select', 'insert', 'update'],
        form: { type: 'text', name: 'email', placeholder: 'Inserisci la mail', isRequired: true, formSort: 15, maxLength: 50 }
    },
    { name: 'email_verified', label: 'Email verificata', format: 'boolean', group: 'Utenza', crud: ['select'], tableSort: 16 },
    {
        name: 'notes', label: 'Note', format: 'string', tableSort: 16, group: 'Altro', crud: ['select', 'insert', 'update'],
        form: { type: 'textarea', name: 'notes', placeholder: 'Eventuali note', isRequired: false, formSort: 16, maxLength: 200 }
    },
    {
        name: 'admin', label: 'Admin', format: 'boolean', group: 'Utenza', crud: ['insert', 'select', 'update'], tableSort: 17,
        form: { type: 'boolean', name: 'admin', isRequired: false, formSort: 17 }
    },
    {
        name: 'student', label: 'Studente', format: 'boolean', group: 'Utenza', crud: ['insert', 'select', 'update'], tableSort: 18,
        form: { type: 'boolean', name: 'student', isRequired: false, formSort: 18 }
    },
    {
        name: 'approved', label: 'Approvato', format: 'boolean', group: 'Utenza', crud: ['select', 'update'], tableSort: 19,
        form: { type: 'boolean', name: 'approved', isRequired: false, formSort: 19 }
    },
    {
        name: 'registration_payed', label: 'Quota iscrizione pagata', format: 'boolean', group: 'Utenza', crud: ['select'], tableSort: 19,
        form: { type: 'boolean', name: 'registration_payed', isRequired: false, formSort: 19 }
    },
    { name: 'creation', label: 'Creata', format: 'datetime', group: 'Utenza', crud: ['select'], tableSort: 20, show: false },
    { name: 'edit', label: 'Modificata', format: 'datetime', group: 'Utenza', crud: ['select'], tableSort: 21, show: false },
    {
        name: 'active', label: 'Attiva', format: 'boolean', group: 'Utenza', crud: ['select', 'update'], tableSort: 22,
        form: { type: 'boolean', name: 'active', isRequired: false, formSort: 22 }
    },
    { name: 'editable', label: 'Modificabile', format: 'hidden', crud: ['select'] }
];
usersList.filters = [
    //{field:'active', value:1, operator:'='} // solo reti attive
];
usersList.options = {
    label: 'Utenti',
    title: 'Elenco utenti',
    detailTitle: 'Utente',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: false,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 25,
    rowClick: 'edit',
    rowActionMenu: true,
    rowActionDetail: false,
    rowActionEdit: true,
    // exportTypes: ["xlsx"],
    defaultSortActive: 'approved',
    defaultSortDirection: 'asc',
    defaultClientFilter: [
        { field: 'active', operator: '=', value: '1' },
        // { field: 'approved', operator: '=', value: '0' }
    ]
};
usersList.linked = [
    // {
    //     title: 'Categorie', sourceName: 'categories_events', idJoin: 'id_event', routerLink: 'eventi', routerId: 'base_url_category',
    //     fields: [
    //         { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
    //         { name: 'id_event', label: 'Id Evento', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
    //         { name: 'id_category', label: 'Id Categoria', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
    //         { name: 'description_category', label: 'Categoria', form: null, crud: ['select'], format: 'string', tableSort: 1 },
    //         { name: 'base_url_category', label: 'Link Categoria', form: null, crud: ['select'], format: 'string', tableSort: 2 },
    //         { name: 'active', label: 'Categoria attiva', form: null, crud: ['select'], format: 'boolean', tableSort: 3 }
    //     ],
    //     filters: [{ field: 'active', value: 1, operator: '=' }],
    //     select: {
    //         sourceName: 'categories',
    //         placeholder: 'Selezionare una categoria',
    //         id: 'id',
    //         idJoin: 'id_category',
    //         label: 'description',
    //         filters: [{ field: 'active', value: 1, operator: '=' }] // solo categorie attive
    //     }
    // },
    // {
    //     title: 'Recensioni', sourceName: 'reviews', idJoin: 'id_event', routerLink: 'evento', routerId: 'base_url',
    //     fields: [
    //         { name: 'id', label: 'Id', form: null, crud: ['select'], format: 'hidden', tableSort: 0 },
    //         { name: 'title', label: 'Titolo', form: null, crud: ['select'], format: 'string', tableSort: 1 },
    //         { name: 'body', label: 'Descrizione', form: null, crud: ['select'], format: 'string', tableSort: 2 },
    //         { name: 'approved', label: 'Approvata', form: null, crud: ['select'], format: 'boolean', tableSort: 3 },
    //         { name: 'active', label: 'Attiva', form: null, crud: ['select'], format: 'boolean', tableSort: 4 }
    //     ],
    //     filters: [{ field: 'active', value: 1, operator: '=' }]
    // }
];

const transactionsList = new EntityModel('');
transactionsList.model = typeof EventModel;
transactionsList.sourceName = 'transactions';
transactionsList.fields = [
    { name: 'id', label: 'ID', format: 'hidden', crud: ['select'] },
    { name: 'title', label: 'Evento', format: 'string', tableSort: 1, crud: ['select'] },
    { name: 'base_url', label: 'URL', format: 'string', tableSort: 2, crud: ['select'] },
    { name: 'fullname', label: 'Utente', format: 'string', tableSort: 3, crud: ['select'] },
    { name: 'pay_method', label: 'Metodo', format: 'string', tableSort: 4, crud: ['select'] },
    { name: 'amount_paid', label: 'Importo', format: 'currency', tableSort: 5, crud: ['select'] },
    { name: 'time_paid', label: 'Data pagamento', format: 'date', tableSort: 6, crud: ['select'] },
];
transactionsList.filters = [
    //{field:'active', value:1, operator:'='} // solo reti attive
];
transactionsList.options = {
    label: 'Transazioni',
    title: 'Elenco transazioni',
    detailTitle: 'Transazione',
    // titleClass: '',
    // tableClass: '',
    // responsive: true,
    sorting: true,
    filterAll: true,
    filterHeader: false,
    pagination: true,
    pageSizeOptions: [10, 25, 100],
    pageSize: 25,
    rowClick: null,
    rowActionMenu: false,
    rowActionDetail: false,
    rowActionEdit: false,
    // exportTypes: ["xlsx"],
    defaultSortActive: 'time_paid',
    defaultSortDirection: 'desc',
    defaultClientFilter: [
        { field: 'id_event_user', operator: '>', value: '0' }
    ]
};
transactionsList.linked = [];


let entities: EntityModel[] = [];
entities['events'] = eventsList;
entities['articles'] = articlesList;
entities['reviews'] = reviewsList;
entities['users'] = usersList;
entities['transactions'] = transactionsList;