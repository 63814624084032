export class EventModel {
    id: number;
    id_user: number;
    name_user: string;
    surname_user: string;
    fullname_user: string;
    title: string;
    description: string;
    available_seats: number;
    price: number;
    main_image: string;
    small_image: string;
    body: string;
    event_date: Date;
    closed: number;
    base_url: string;
    creation: Date;
    edit: Date;
    active: number;
    editable: number;
}