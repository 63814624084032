<button type="button" mat-button (click)="navigation_back()"><mat-icon>chevron_left</mat-icon>Indietro</button>
<div *ngIf="articleLoaded" class="container">
    <p class="text-center" style="margin-top: 30px;">{{article['creation'] | date}}</p>
    <h3 class="text-center"><strong>{{article['title']}}</strong></h3>
    <h5 class="text-center"><strong>{{article['description']}}</strong></h5>
    <img src="{{article['main_image']}}" style="margin-top: 20px;margin-bottom: 20px;width: 100%;max-width: 400px;">
    <p style="font-weight: 400;" [innerHTML]="article['body']"></p>
</div>
<div *ngIf="categories.length" class="container">
    <h4 style="font-weight: bold">Categorie dell'articolo</h4>
    <div class="row" style="padding: 15px">
        <a *ngFor="let category of categories" class="col-6 col-sm-4 col-md-3" style="margin-bottom: 10px; text-decoration: none;"
            routerLink="/news/{{ category.base_url_category }}">
            <div class="d-sm-flex justify-content-sm-center align-items-sm-center"
                style="min-width: 100%;max-width: 100%;padding: 6px;border-radius: 20px;background-color: #00000065; border: 1px solid rgba(33,37,41,0.49);min-height: 100%;max-height: 100%;">
                <h5 class="text-center d-sm-flex" style="margin-bottom: 0px; color: white !important;">
                    {{category.description_category}}
                </h5>
            </div>
        </a>
    </div>
</div>
<div *ngIf="lastArticles.length" class="container" style="padding-top: 50px;">
    <h3 class="text-center">Altre news che potrebbero interessarti</h3>
    <div class="row">
        <div *ngFor="let article of lastArticles" class="col-12 col-sm-6 col-md-4 col-lg-4" style="padding: 10px;"
            (click)="customRoute(article.record_type == 'article' ? '/articolo/'+article.base_url_article : '/evento/'+article.base_url_article)">
            <div class="card_hoverable">
                <img src="{{article.main_image}}"
                    style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                <div style="padding: 15px;">
                    <h5><strong>{{article.title_article}}</strong></h5>
                    <p>{{article.description_article}}</p>
                    <div class="text-end" style="min-width: 100%;">
                        <a class="detail_btn btn btn-primary text-center" *ngIf="article.record_type == 'article'" href="/articolo/{{article.base_url_article}}">Leggi articolo</a>
                        <a class="detail_btn btn btn-primary text-center" *ngIf="article.record_type == 'event'" routerLink="/evento/{{article.base_url_article}}">Visualizza
                            evento</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>