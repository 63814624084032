import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-mobile',
  template: '',
})
export class MobileComponent implements OnInit {
  constructor(private storage: StorageService) { }

  ngOnInit() {
    this.storage.setMobileFlag();
    window.location.href = '/'
  }
}
