import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
    return next.handle(request).pipe(catchError(err => {
      console.log('error interceptor');
      // Gestione migrata nel jwt.interceptor per integrazione refreshToken
      // if ([401, 403].indexOf(err.status) !== -1) {
      //   // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      //   this.auth.logout();
      // }
      if ([425].indexOf(err.status) !== -1) {
        // accesso con password temporanea
        this.auth.expiredPassword();
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
