import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtInterceptor } from './services/jwt.interceptor';
import { ErrorInterceptor } from './services/error.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environment/environment';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { EntityDetailComponent } from './components/entity-detail/entity-detail.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { IndexComponent } from './components/index/index.component';
import { MobileComponent } from './components/index/mobile.component';
import { IscrizioneComponent } from './components/iscrizione/iscrizione.component';
import { EventiComponent } from './components/eventi/eventi.component';
import { EventoComponent } from './components/evento/evento.component';
import { NewsComponent } from './components/news/news.component';
import { PrenotazioniComponent } from './components/prenotazioni/prenotazioni.component';
import { ArticoloComponent } from './components/articolo/articolo.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import '@angular/common/locales/global/it';
import { GoogleMapsModule } from '@angular/google-maps';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ConfirmOrderComponent } from './components/confirm-order/confirm-order.component';
import { CancelOrderComponent } from './components/cancel-order/cancel-order.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ControllaAccreditoComponent } from './components/controlla-accredito/controlla-accredito.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { RouterModule } from '@angular/router';
import { DeleteProfileComponent } from './components/delete-profile/delete-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    LogoutComponent,
    EntityListComponent,
    EntityDetailComponent,
    ChangePasswordComponent,
    ProfileComponent,
    TermsAndConditionsComponent,
    IndexComponent,
    MobileComponent,
    IscrizioneComponent,
    EventiComponent,
    EventoComponent,
    NewsComponent,
    PrenotazioniComponent,
    ArticoloComponent,
    ForgotPasswordComponent,
    ConfirmationComponent,
    CheckoutComponent,
    ConfirmOrderComponent,
    CancelOrderComponent,
    ControllaAccreditoComponent,
    DeleteProfileComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      timeOut: 3000,
      progressBar: true
    }),
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    CdkAccordionModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatBadgeModule,
    MatRippleModule,
    NgbModule,
    AngularEditorModule,
    RecaptchaV3Module,
    GoogleMapsModule,
    QRCodeModule,
    ZXingScannerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MatDatepickerModule,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaPK },
    { provide: LOCALE_ID, useValue: 'it-IT' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
