import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.css',
})
export class ConfirmationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  code: string;
  loading: boolean = true;
  result: boolean = false;
  recaptchaSubscription: Subscription;

  ngOnInit() {
    this.code = this.route.snapshot.params.id;
    this.confirmation();
  }
  ngOnDestroy() {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }
  ngAfterViewInit() {
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }
  confirmation() {
    this.recaptchaSubscription = this.recaptchaV3Service
      .execute('confirmation')
      .subscribe((token) => {
        this.api.confirmation(this.code, token).subscribe({
          next: (res) => {
            this.result = true;
            this.loading = false;
          },
          error: (err) => {
            this.result = false;
            this.loading = false;
          },
        });
      });
  }
}
