import { Component } from '@angular/core';
import { FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.services';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrl: './delete-profile.component.css'
})
export class DeleteProfileComponent {
  userData: UserModel;
  inputUsername: string;

  constructor(
    private storage: StorageService,
    private notifier: NotifierService,
    private api: ApiService,
    private auth: AuthService
  ) {
    this.userData = this.storage.getUserData();
  }

  deleteUser() {
    if (this.inputUsername != this.userData['username']) {
      this.notifier.showWarning('Attenzione', 'Username non corretta');
    } else {
      this.api.delete_user().subscribe({
      // const _fields = { username: null, email: null, active: 0 }
      // const _filters: FiltersModel[] = [{ field: 'username', operator: '=', value: this.userData['username'] }]
      // this.api.update(null, 'users', _fields, _filters).subscribe({
        next: (data) => {
          this.auth.logout();
        },
        error: (err) => {
          // this.notifier.showError('Errore','Si è verificato un errore: ' + err?.error?.detail);
        }
      })
    }
  }
}
