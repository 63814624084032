<div class="container">
    <div class="row mb-2">
        <div class="col-md-8 col-xl-6 text-center mx-auto">
            <h2>Log in</h2>
            <p class="w-lg-50"></p>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-md-6 col-xl-4">
            <div class="card mb-5">
                <div class="card-body d-flex flex-column align-items-center">
                    <div class="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4"
                        style="background: rgb(253,13,13);">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                            viewBox="0 0 16 16" class="bi bi-person">
                            <path
                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z">
                            </path>
                        </svg>
                    </div>
                    <form class="text-center" [formGroup]="form" ngNativeValidate (ngSubmit)="login()">
                        <div class="mb-3">
                            <input #username formControlName="username" class="form-control" type="text" name="Username"
                                placeholder="Username" required="true">
                        </div>

                        <div class="input-group mb-3">
                            <input formControlName="password" class="form-control"
                                [type]="showPassword ? 'text' : 'password'" name="password" placeholder="Password"
                                required="true">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                    style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;" type="button"
                                    (click)="showPassword=!showPassword">
                                    <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path
                                            d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                    <svg *ngIf="!showPassword" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                        <path
                                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                        <path
                                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div class="mb-3">
                            <button class="btn btn-primary d-block w-100" type="submit"
                                style="background: rgb(253,13,13);">Login</button>
                        </div>
                        <div class="mb-3">
                            <a routerLink="/password-dimenticata" class="text-muted">Dimenticato la password?</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>