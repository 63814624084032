import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
// import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrl: './evento.component.css',
})
export class EventoComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindowEl: MapInfoWindow;
  @ViewChildren(MapMarker) markersEl: QueryList<MapMarker>;

  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService
  ) { }

  baseUrl: string;
  userData: UserModel;
  isLogged: boolean = false;
  isStudent: boolean = false;
  isPartner: boolean = false;
  lastEvents = [];
  event = {};
  eventLoaded = false;
  categories = [];
  reviews = [];
  showMap: boolean = false;
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  zoom = 13;
  options = {};
  markers = [];
  limitLast = 4;
  offsetLast = 0;
  user = {};
  eventRegistered: boolean;

  ngOnInit() {
    this.baseUrl = this.route.snapshot.params.id;
    this.loadData();
    this.userData = this.storage.getUserData();
    if (this.userData) {
      // this.isLogged = this.userData?.admin != undefined;
      this.isLogged = true;
      this.isStudent = !!this.userData?.student;
      this.isPartner = !this.userData?.student;
    }
  }

  loadData() {
    this.getEvent();
    this.getLastEvents();
  }
  getEvent() {
    const _entity = 'events';
    const _fields = [
      'id',
      'title',
      'description',
      'body',
      'main_image',
      'base_url',
      'price',
      'date',
      'time',
      'place',
      'address',
      'city',
      'province',
      'lat',
      'lng',
      'closed',
      'available_seats',
      'reserved_student_seats',
      'reserved_partner_seats',
      'count_users',
      'count_students',
      'count_partners'
    ];
    const _filters: FiltersModel[] = [
      { field: 'base_url', operator: '=', value: this.baseUrl },
      { field: 'active', operator: '=', value: 1 },
    ];
    const _sort = [];
    const _options = {};
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        if (data.length == 0) {
          this.router.navigate(['/']);
        }
        this.event = data[0];
        this.getCategoriesList();
        this.getReviews();
        this.eventLoaded = true;
        if(this.isLogged)
          this.getUser()
        // gestione dei contatori dei posti disponibili
        this.event['available_partners'] = this.event['available_seats'] - this.event['count_partners'] - Math.max(this.event['reserved_student_seats'], this.event['count_students']);
        this.event['available_students'] = this.event['available_seats'] - this.event['count_students'] - Math.max(this.event['reserved_partner_seats'], this.event['count_partners']);
        const lat = this.event['lat'];
        const lng = this.event['lng'];
        if (lat != null && !isNaN(lat) && lng != null && !isNaN(lng)) {
          this.showMap = true;
          this.center = { lat: lat, lng: lng };
          let marker = {
            position: { lat: lat, lng: lng },
            title: this.event['place'],
          };
          this.markers.push(marker);
        }
      });
  }
  getUser() {
    if (this.userData.username != '') {
      const _entity = 'users';
      const _fields = ['id'];
      const _filters: FiltersModel[] = [
        { field: 'username', operator: '=', value: this.userData.username }
      ];
      const _sort = [];
      const _options = {};
      this.api.select(null, _entity, _fields, _filters, _sort, _options).subscribe(
        data => {
          this.user = data[0];
          this.isEventRegistered()
        });
    }
  }
  getCategoriesList() {
    const _entity = 'categories_events';
    const _fields = ['description_category', 'base_url_category'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'id_event', operator: '=', value: this.event['id'] },
      // gestione del caso di evento senza categoria
      { field: 'id_category', operator: '>', value: 0 },
    ];
    const _sort = [];
    const _options = {
      distinct: true,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.categories = data;
      });
  }
  getReviews() {
    const _entity = 'reviews';
    const _fields = ['title', 'body'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'approved', operator: '=', value: 1 },
      { field: 'id_event', operator: '=', value: this.event['id'] },
    ];
    const _sort = [{ field: 'id', direction: 'desc' }];
    const _options = {
      limit: 4,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.reviews = data;
      });
  }
  getLastEvents() {
    const _entity = 'events';
    const _fields = [
      'title',
      'description',
      'date',
      'time',
      'main_image',
      'base_url',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'base_url', operator: '!=', value: this.baseUrl },
    ];
    const _sort = [{ field: 'creation', direction: 'desc' }];
    const _options = {
      limit: this.limitLast,
      offset: this.offsetLast,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.lastEvents = data;
      });
  }
  isEventRegistered(){
    const _entity = 'events_users';
    const _fields = ['id'];
    const _filters: FiltersModel[] = [
      { field: 'id_user', operator: '=', value: this.user['id'] },
      { field: 'id_event', operator: '=', value: this.event['id'] },
    ];
    const _sort = [];
    const _options = { count: true };
    this.api
      .select(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.eventRegistered = data[0]['queryCount'] == 1 ? true : false;
      });
  }
  openInfoWindow(windowIndex: number, content: string) {
    this.markersEl.forEach((marker: MapMarker, ix: number) => {
      if (windowIndex === ix) {
        this.infoWindowEl.open(marker);
      }
    });
  }
  checkout() {
    console.log('createCart: ' + this.event['id']);
    this.storage.createCart(this.event['id']);
    this.router.navigate(['checkout']);
  }
  customRoute(url) {
    window.location.href = url;
  }

  navigation_back() {
    window.history.go(-1); return false;
  }
}
