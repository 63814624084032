import { Component, InjectionToken } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.css'
})
export class CheckoutComponent {
  constructor(
    private fb: FormBuilder,
    private storage: StorageService,
    private api: ApiService,
    private router: Router,
    private notifier: NotifierService,
  ) {
    this.form = this.fb.group({
      fiscal_code: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      residence_cap: ['', Validators.required],
      residence_address: ['', Validators.required],
      residence_city: ['', Validators.required],
      residence_province: ['', Validators.required],
      phone: ['', Validators.required],
    });
  }

  form: FormGroup;
  productId: number;
  userData: UserModel;
  isLogged: boolean = false;
  isStudent: boolean = false;
  isPartner: boolean = false;
  event = {};
  eventLoaded = false;
  user = {};
  userLoaded: boolean = false;
  completedUser: boolean;

  ngOnInit() {
    this.productId = Number(this.storage.getCart());
    this.userData = this.storage.getUserData();
    if (this.userData) {
      this.isLogged = true;
      this.isStudent = this.userData?.student;
      this.isPartner = !this.userData?.student;
    }
    this.loadData();
  }

  ngOnDestroy() {
    console.log('deleteCart');
    this.storage.deleteCart();
  }

  loadData() {
    this.getEvent();
    this.getUser();
  }
  getEvent() {
    const _entity = 'events';
    const _fields = ['id', 'title', 'description', 'price', 'date', 'time', 'place', 'address', 'city', 'province'];
    const _filters: FiltersModel[] = [
      { field: 'id', operator: '=', value: this.productId },
      { field: 'active', operator: '=', value: 1 }
    ];
    const _sort = [];
    const _options = {};
    this.api.pSelect(null, _entity, _fields, _filters, _sort, _options).subscribe(
      data => {
        if (data.length == 0) {
          this.router.navigate(['/']);
        }
        this.event = data[0];
        this.eventLoaded = true;
      });
  }
  getUser() {
    if (this.userData.username != '') {
      const _entity = 'users';
      const _fields = ['id', 'admin', 'student', 'name', 'surname', 'residence_cap', 'residence_address', 'residence_city',
        'residence_province', 'fiscal_code', 'phone', 'email', 'registration_payed'];
      const _filters: FiltersModel[] = [
        { field: 'username', operator: '=', value: this.userData.username }
      ];
      const _sort = [];
      const _options = {};
      this.api.select(null, _entity, _fields, _filters, _sort, _options).subscribe(
        data => {
          this.user = data[0];
          this.userLoaded = true;
          this.completedUser = this.user['surname']?.length > 0 && this.user['residence_address']?.length > 0;
        });
    }
  }

  createOrder() {
    this.api.createOrder(this.user['id'], this.event['id']).subscribe({
      next: (data) => {
        const _url = data.toString();
        if (_url.length > 0) {
          // il backend ha creato l'ordine e ha restituito l'url a cui reindirizzare l'utente per il pagamento
          window.location.href = _url;
        } else {
          this.notifier.showError('Attenzione', 'Si è verificato un errore imprevisto');
        }
      },
      error: (err) => {
        this.notifier.showError('Attenzione', err?.error?.detail);
      }
    });
  }

  updateUser() {
    let dirtyFields = {};
    if (!this.user['id']) {
      console.log('return')
      return;
    }
    // ferma l'esecuzione se la form non è valida
    if (this.form.invalid) {
      this.notifier.showWarning('Attenzione', 'Form non valida');
      return;
    }
    Object.keys(this.form.controls).forEach((c) => {
      let currentControl = this.form.controls[c];
      if (currentControl.dirty) dirtyFields[c] = currentControl.value;
    });
    // preparo le condizioni di filtro dell'oggetto
    const updateFilters: FiltersModel[] = [{ field: 'id', value: this.user['id'], operator: '=' }];
    this.api.update(null, 'users', dirtyFields, updateFilters).subscribe({
      next: (response) => {
        this.form.markAsPristine();
        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
        this.createOrder();
      },
      error: (err) => {
        this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail + ' - ' + err);
      },
    });
    return;
  }
}

