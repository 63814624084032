import { Component } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EntityModel, FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormModalComponent } from 'src/app/shared/form-modal/form-modal.component';

@Component({
  selector: 'app-prenotazioni',
  // standalone: true,
  // imports: [],
  templateUrl: './prenotazioni.component.html',
  styleUrl: './prenotazioni.component.css',
})
export class PrenotazioniComponent {
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private storage: StorageService,
    public dialog: MatDialog,
    private notifier: NotifierService,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      body: ['', Validators.required],
    });
  }

  form: FormGroup;
  userData: UserModel;
  isLogged: boolean = false;
  isStudent: boolean = false;
  isPartner: boolean = false;
  user = {};
  userEvents = [];
  limitUserEvents = 6;
  offsetUserEvents = 0;
  loadingUserEvents: boolean = false;
  loadedUserEvents: boolean = false;

  ngOnInit() {
    this.userData = this.storage.getUserData();
    if (this.userData) {
      this.isLogged = true;
      this.isStudent = this.userData?.student;
      this.isPartner = !this.userData?.student;
    }
    this.getUser();
  }
  getUser() {
    if (this.userData.username != '') {
      const _entity = 'users';
      const _fields = ['id'];
      const _filters: FiltersModel[] = [
        { field: 'username', operator: '=', value: this.userData.username },
      ];
      const _sort = [];
      const _options = {};
      this.api
        .select(null, _entity, _fields, _filters, _sort, _options)
        .subscribe((data) => {
          this.user = data[0];
          this.getUserEvents();
        });
    }
  }
  getUserEvents(mode?: 'append') {
    const _entity = 'events_users';
    const _fields = ['id', 'id_event', 'title', 'description', 'date', 'time', 'main_image', 'base_url', 'place', 'address', 'city', 'province', 'qrcode', 'closed', 'review_inserted'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'id_user', operator: '=', value: this.user['id'] },
    ];
    const _sort = [{ field: 'date', direction: 'desc' }];
    const _options = {
      // TODO Gestire il caricamento paginato degli eventi
      // limit: this.limitUserEvents,
      // offset: this.offsetUserEvents,
    };
    this.api
      .select(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        if (mode == 'append') this.userEvents.push(...data);
        else {
          this.userEvents = data;
          this.loadedUserEvents = true;
        }
        this.loadedUserEvents = true;
      });
  }
  moreRecords(type) {
    switch (type) {
      case 'nextUserEvents': {
        this.loadingUserEvents = true;
        this.offsetUserEvents += this.limitUserEvents;
        this.getUserEvents('append');
        break;
      }
      default:
        break;
    }
  }

  insertReview(_idEvent: number): void {
    const _fields = {
      id_user: this.user['id'],
      id_event: _idEvent,
      title: this.form.controls['title'].value,
      body: this.form.controls['body'].value,
    };

    this.api.insert(null, 'reviews', _fields).subscribe({
      next: (response) => {
        this.form.markAsPristine();
        this.notifier.showSuccess(
          'Complimenti',
          'Operazione avvenuta correttamente'
        );
      },
      error: (err) => {
        this.notifier.showError(
          'Errore',
          'Si è verificato un errore: ' + err?.error?.detail + ' - ' + err
        );
      },
    });
  }
}
