import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from './guards/role-guard.guard';

import { EntityDetailComponent } from './components/entity-detail/entity-detail.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { IndexComponent } from './components/index/index.component';
import { NewsComponent } from './components/news/news.component';
import { EventiComponent } from './components/eventi/eventi.component';
import { IscrizioneComponent } from './components/iscrizione/iscrizione.component';
import { PrenotazioniComponent } from './components/prenotazioni/prenotazioni.component';
import { EventoComponent } from './components/evento/evento.component';
import { ArticoloComponent } from './components/articolo/articolo.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ConfirmOrderComponent } from './components/confirm-order/confirm-order.component';
import { ControllaAccreditoComponent } from './components/controlla-accredito/controlla-accredito.component';
import { CancelOrderComponent } from './components/cancel-order/cancel-order.component';
import { DeleteProfileComponent } from './components/delete-profile/delete-profile.component';
import { MobileComponent } from './components/index/mobile.component';

const routes: Routes = [
  {
    path: 'mobile',
    component: MobileComponent,
  },
  {
    path: 'gestione-eventi/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'events' },
  },
  {
    path: 'gestione-eventi', component: EntityListComponent,
    canActivate: [RoleGuard], data: { roles: ['admin'], source: 'events' },
  },
  {
    path: 'gestione-news/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'articles' },
  },
  {
    path: 'gestione-news', component: EntityListComponent,
    canActivate: [RoleGuard], data: { roles: ['admin'], source: 'articles' },
  },
  {
    path: 'gestione-utenti', component: EntityListComponent,
    canActivate: [RoleGuard], data: { roles: ['admin'], source: 'users' },
  },
  {
    path: 'gestione-recensioni', component: EntityListComponent,
    canActivate: [RoleGuard], data: { roles: ['admin'], source: 'reviews' },
  },
  {
    path: 'gestione-transazioni', component: EntityListComponent,
    canActivate: [RoleGuard], data: { roles: ['admin'], source: 'transactions' },
  },
  {
    path: 'controlla-accrediti', component: ControllaAccreditoComponent,
    canActivate: [RoleGuard], data: { roles: ['admin'] },
  },
  {
    path: 'checkout', component: CheckoutComponent,
    canActivate: [RoleGuard], data: { roles: ['user'] },
  },
  {
    path: 'confirm_stripe/:code', component: ConfirmOrderComponent,
    canActivate: [RoleGuard], data: { roles: ['user'] },
  },
  {
    path: 'cancel_stripe/:code', component: CancelOrderComponent,
    canActivate: [RoleGuard], data: { roles: ['user'] },
  },

  { path: 'cambio-password', component: ChangePasswordComponent },
  { path: 'password-dimenticata', component: ForgotPasswordComponent },
  { path: 'confirmation/:id', component: ConfirmationComponent },

  // { path: 'articolo', component: ArticoloComponent },
  {
    path: 'prenotazioni', component: PrenotazioniComponent,
    canActivate: [RoleGuard], data: { roles: ['user'] },
  },
  { path: 'login', component: LoginComponent },
  { path: 'iscriviti', component: IscrizioneComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news/:id', component: NewsComponent },
  { path: 'articolo/:id', component: ArticoloComponent },
  { path: 'eventi', component: EventiComponent },
  { path: 'eventi/ricerca/:search', component: EventiComponent },
  { path: 'eventi/:id', component: EventiComponent },
  { path: 'evento/:id', component: EventoComponent },
  {
    path: 'profilo', component: ProfileComponent,
    canActivate: [RoleGuard], data: { roles: ['user'] },
  },
  {
    path: 'cancella-profilo', component: DeleteProfileComponent,
    canActivate: [RoleGuard], data: { roles: ['user'] },
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'termini-condizioni', component: TermsAndConditionsComponent },
  { path: '', component: IndexComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
