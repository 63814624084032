import { Component } from '@angular/core';
import { FiltersModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-controlla-accredito',
  templateUrl: './controlla-accredito.component.html',
  styleUrl: './controlla-accredito.component.css',
})
export class ControllaAccreditoComponent {
  constructor(
    private api: ApiService,
    private notifier: NotifierService
  ) { }

  scannedValue: string;
  scanCompleted: boolean = false;
  checkComplete: boolean = false;
  checkResult: boolean = false;
  userEvent = {};

  scanSuccess(e) {
    this.scanCompleted = true;
    this.scannedValue = e;
    const _entity = 'events_users';
    const _fields = ['title', 'date', 'time', 'fullname'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 0 },
      { field: 'qrcode', operator: '=', value: this.scannedValue },
    ];
    const _sort = [];
    const _options = {
      limit: 1,
    };
    this.api
      .select(null, _entity, _fields, _filters, _sort, _options)
      .subscribe({
        next: (data) => {
          this.checkComplete = true;
          if (data.length > 0) {
            // risposta positiva dall'api e ottenuto un record
            this.userEvent = data[0];
            this.checkResult = true;
          } else {
            // risposta positiva dall'api ma nessun record restituito
            this.checkResult = false;
          }
        },
        error: (err) => {
          // errore dell'api
          this.checkComplete = true;
          this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail);
        },
      });
  }
  camerasNotFoundHandler(e) {
    this.notifier.showError('Attenzione', 'Non è stata rilevata alcuna fotocamera');
  }
  scanErrorHandler(e) {
    this.notifier.showError('Attenzione', 'Si è verificato un errore. Provare a ricaricare la pagina ed effettuare una nuova scansione.');
  }
  newScan() {
    this.checkResult = false;
    this.checkComplete = false;
    this.scanCompleted = false;
  }
}
