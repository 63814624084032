import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.services';

declare function mobile_logout(): any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
    // try {
    //   // codice per gestire il logout nativo dell'app mobile
    //   mobile_logout();
    // }
    // catch {
      // se va in errore il logout mobile, allora serve il logout web
      // console.log('Web logout');
      this.auth.logout();
    // }
  }
}
