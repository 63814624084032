<div class="container pt-3">
    <div class="row">
        <div class="col-12 col-md-5">
            <h1 style="text-decoration: underline;">Profilo</h1>
            <h3>Informazioni personali</h3>
            <a class="btn btn-primary text-center" type="submit" routerLink="/cambio-password"
                style="background:rgb(253,13,13);margin-bottom:auto;margin-left:auto;">
                Cambio&nbsp;password
            </a>
            <br />
            <a class="btn btn-primary text-center" type="submit" routerLink="/cancella-profilo"
                style="background:rgb(253,13,13);margin-bottom:auto;margin-left:auto; margin-top: 16px;">
                Cancella&nbsp;profilo
            </a>
        </div>
        <div class="col-12 col-md-7 pt-4 pt-md-0">
            <form method="post" [formGroup]="form" ngNativeValidate (ngSubmit)="updateUser()">
                <p style="font-weight: 500">Utenza</p>
                <input class="form-control mb-3" type="text" formControlName="username" readonly>
                <input class="form-control mb-3" type="text" formControlName="role" readonly>
                <input class="form-control mb-3" type="text" formControlName="isActive" readonly>
                <input class="form-control mb-3" type="email" formControlName="email" placeholder="Email *"
                    autocomplete="new-email" required="true" maxlength="50">
                <!-- <input class="form-control mb-3" type="password" formControlName="tmp_password"
                    autocomplete="new-password" placeholder="Cambio password"> -->
                <p style="font-weight: 500">Generalità</p>
                <input class="form-control mb-3" type="text" formControlName="name" placeholder="Nome *" required="true"
                    maxlength="45">
                <input class="form-control mb-3" type="text" formControlName="surname" placeholder="Cognome *"
                    required="true" maxlength="45">
                <select class="form-select mb-3" formControlName="id_gender" required="true">
                    <option value="" disabled selected>Sesso *</option>
                    <option *ngFor="let gender of genders" value="{{gender.id}}">{{gender.description}}</option>
                </select>
                <input class="form-control mb-3" type="text" formControlName="fiscal_code"
                    placeholder="Codice Fiscale *" required="true" maxlength="16">
                <input class="form-control mb-3" type="text" formControlName="phone" placeholder="Telefono *"
                    required="true" maxlength="15">
                <p style="font-weight: 500">Nascita</p>
                <input class="form-control mb-3" type="date" formControlName="birth_date"
                    placeholder="Data di nascita *" required="true">
                <input class="form-control mb-3" type="text" formControlName="birth_place"
                    placeholder="Luogo di nascita *" required="true" maxlength="50">
                <input class="form-control mb-3" type="text" formControlName="birth_province"
                    placeholder="Provincia di nascita *" required="true" maxlength="2">
                <p style="font-weight: 500">Residenza</p>
                <input class="form-control mb-3" type="text" formControlName="residence_address"
                    placeholder="Indirizzo di residenza *" required="true" maxlength="50">
                <input class="form-control mb-3" type="text" formControlName="residence_city"
                    placeholder="Comune di residenza *" required="true" maxlength="50">
                <input class="form-control mb-3" type="text" formControlName="residence_province"
                    placeholder="Provincia di residenza *" arequired="true" maxlength="2">
                <input class="form-control mb-3" type="text" formControlName="residence_cap"
                    placeholder="CAP di residenza *" required="true" maxlength="5">
                <textarea class="form-control mb-3 mt-5" formControlName="notes"
                    placeholder="Inserisci eventuali note"></textarea>
                <div class="text-end" style="width: 100%;">
                    <button class="btn btn-primary text-center d-block w-100" type="submit"
                        style="background: rgb(253,13,13);width: 100%;max-width: 100px;margin-bottom: auto;margin-left: auto;">Salva</button>
                </div>
            </form>
        </div>
    </div>
</div>