import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private storage: StorageService,
    private notifier: NotifierService,
    private api: ApiService
  ) {
    this.form = this.fb.group({
      username: [{ value: '', disabled: true }],
      role: [{ value: '', disabled: true }],
      isActive: [{ value: '', disabled: true }],
      // tmp_password: [''],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      id_gender: ['', Validators.required],
      birth_date: ['', Validators.required],
      birth_place: ['', Validators.required],
      birth_province: ['', Validators.required],
      residence_cap: ['', Validators.required],
      residence_address: ['', Validators.required],
      residence_city: ['', Validators.required],
      residence_province: ['', Validators.required],
      fiscal_code: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      notes: [''],
    });
  }

  form: FormGroup;
  userData: UserModel;
  genders = [];
  isLogged: boolean = false;
  isStudent: boolean = false;
  isPartner: boolean = false;
  user = {};
  userLoaded: boolean = false;
  userStatus: string = '';

  ngOnInit() {
    this.userData = this.storage.getUserData();
    if (this.userData) {
      this.isLogged = true;
      this.isStudent = this.userData?.student;
      this.isPartner = !this.userData?.student;
    }
    this.loadData();
  }

  loadData() {
    this.getUser();
    this.getGenders();
  }
  getGenders() {
    const _entity = 'genders';
    const _fields = ['id', 'description'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
    ];
    const _sort = [{ field: 'id', direction: 'asc' }];
    const _options = {};
    this.api
      .select(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.genders = data;
      });
  }
  getUser() {
    if (this.userData.username != '') {
      const _entity = 'users';
      const _fields = [
        'id',
        'username',
        'admin',
        'student',
        'name',
        'surname',
        'id_gender',
        'birth_date',
        'birth_place',
        'birth_province',
        'residence_cap',
        'residence_address',
        'residence_city',
        'residence_province',
        'fiscal_code',
        'phone',
        'email',
        'notes',
        'active',
      ];
      const _filters: FiltersModel[] = [
        { field: 'username', operator: '=', value: this.userData.username },
      ];
      const _sort = [];
      const _options = {};
      this.api
        .select(null, _entity, _fields, _filters, _sort, _options)
        .subscribe((data) => {
          this.user = data[0];
          this.form.patchValue(this.user);
          this.userLoaded = true;
          this.form.controls['isActive'].setValue(
            this.user['active'] ? 'Utente attivo' : 'Utente non attivo'
          );
          this.form.controls['role'].setValue(
            this.user['admin']
              ? 'Amministratore'
              : this.user['student']
                ? 'Studente'
                : 'Socio'
          );
        });
    }
  }
  updateUser() {
    let dirtyFields = {};
    if (!this.user['id']) {
      return;
    }
    // ferma l'esecuzione se la form non è valida
    if (this.form.invalid) {
      this.notifier.showWarning('Attenzione', 'Form non valida');
      return;
    }
    if (!this.form.dirty) {
      this.notifier.showWarning('Attenzione', 'Nessuna modificare da salvare');
      return;
    }
    Object.keys(this.form.controls).forEach((c) => {
      let currentControl = this.form.controls[c];
      if (currentControl.dirty) dirtyFields[c] = currentControl.value;
    });
    // preparo le condizioni di filtro dell'oggetto
    const updateFilters: FiltersModel[] = [{ field: 'id', value: this.user['id'], operator: '=' }];
    this.api.update(null, 'users', dirtyFields, updateFilters).subscribe({
      next: (response) => {
        this.form.markAsPristine();
        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
        this.loadData();
      },
      error: (err) => {
        this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail + ' - ' + err);
      },
    });
    return;
  }
}
