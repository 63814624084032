<div class="container" style="min-height: 400px;">
    <h3 style="margin-top: 50px;">Le mie prenotazioni</h3>
    <div class="row">
        <div class="col-md">
            <p *ngIf="!loadedUserEvents">Caricamento...</p>
            <p *ngIf="loadedUserEvents && !userEvents.length">Nessuna prenotazione</p>
            <div class="row" *ngFor="let event of userEvents">
                <div class="col-12" style="padding: 25px;border-radius: 15px;border-width: 1px;">
                    <div class="row" style="border-radius: 15px;border: 1px solid grey ;">
                        <div class="col-md-3 col-lg-3 col-xl-3 text-center" style="padding: 10px;overflow: hidden;">
                            <img src="{{event['main_image']}}" style="height: 100%; max-height: 150px;">
                        </div>
                        <div class="col text-center" style="padding: 10px;">
                            <h4><strong>{{event['title']}}</strong></h4>
                            <h5>{{event['date'] | date : 'fullDate'}} ore {{event['time']*1000 | date: 'HH:mm':'UTC'}}
                            </h5>
                            <h5>{{event['place']}}, {{event['address']}} {{event['city']}} {{event['province']}}</h5>

                            <button *ngIf="event['closed'] == 0" class="btn btn-primary" type="button"
                                [attr.data-bs-target]="'#modal-'+event['id']" data-bs-toggle="modal"
                                style="background: rgb(253,13,13);">QrCode</button>
                            <div *ngIf="event['closed'] == 0" class="modal fade" role="dialog" tabindex="-1"
                                id="modal-{{event['id']}}">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">{{event['title']}}</h4>
                                            <button class="btn-close" type="button" aria-label="Close"
                                                data-bs-dismiss="modal"></button>
                                        </div>
                                        <div class="modal-body">
                                            <p>{{event['date'] | date : 'fullDate'}} ore {{event['time']*1000 | date:
                                                'HH:mm':'UTC'}}</p>
                                            <qrcode [qrdata]="event['qrcode']" [width]="256"
                                                [errorCorrectionLevel]="'M'"></qrcode>
                                        </div>
                                        <div class="modal-footer"></div>
                                    </div>
                                </div>
                            </div>

                            <button *ngIf="event['closed'] == 1 && event['review_inserted'] == 0"
                                class="btn btn-primary" type="button" [attr.data-bs-target]="'#review-'+event['id']"
                                data-bs-toggle="modal">Lascia una recensione</button>
                            <div *ngIf="event['closed'] == 1 && event['review_inserted'] == 0" class="modal fade"
                                role="dialog" tabindex="-1" id="review-{{event['id']}}">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <form method="post" [formGroup]="form" ngNativeValidate
                                            (ngSubmit)="insertReview(event['id_event'])">
                                            <div class="modal-header">
                                                <h4 class="modal-title">{{event['title']}}</h4>
                                                <button class="btn-close" type="button" aria-label="Close"
                                                    data-bs-dismiss="modal"></button>
                                            </div>
                                            <div class="modal-body">
                                                <input class="form-control mb-3" type="text" formControlName="title"
                                                    placeholder="Titolo *" required="true" maxlength="150">
                                                <textarea class="form-control mb-3" formControlName="body"
                                                    placeholder="Descrizione *" required="true"
                                                    maxlength="1500"></textarea>
                                            </div>
                                            <div class="modal-footer">
                                                <button class="btn btn-primary" type="submit">Invia</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="event['closed'] == 1 && event['review_inserted'] == 1">
                                <p>Recensione inviata</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Ricorda che una prenotazione è vincolante, cancella la tua prenotazione (oppure "avvertici se non puoi venire") così da permettere ad altri di partecipare.</p>
        </div>
    </div>
</div>