import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FiltersModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import CodiceFiscale from 'codice-fiscale-js';

@Component({
  selector: 'app-iscrizione',
  templateUrl: './iscrizione.component.html',
  styleUrl: './iscrizione.component.css',
})
export class IscrizioneComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private router: Router,
    private api: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.form = this.fb.group({
      username: ['', Validators.required],
      tmp_password: ['', Validators.required],
      // name: ['', Validators.required],
      // surname: ['', Validators.required],
      // id_gender: ['', Validators.required],
      // birth_date: ['', Validators.required],
      // birth_place: ['', Validators.required],
      // birth_province: ['', Validators.required],
      // residence_cap: ['', Validators.required],
      // residence_address: ['', Validators.required],
      // residence_city: ['', Validators.required],
      // residence_province: ['', Validators.required],
      // fiscal_code: ['', Validators.required],
      // phone: ['', Validators.required],
      email: ['', Validators.required],
      student: [],
      // notes: [''],
    });
  }

  recaptchaSubscription: Subscription;
  form: FormGroup;
  genders = [];
  step2 = false;
  showPassword: boolean = false;

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  loadData() {
    this.getGenders();
  }
  getGenders() {
    const _entity = 'genders';
    const _fields = ['id', 'description'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
    ];
    const _sort = [{ field: 'id', direction: 'asc' }];
    const _options = {};
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.genders = data;
      });
  }
  checkBirthDate() {
    if (this.step2 == true) {
      // se step è 2, vuol dire che già è stato una prima volta valutato il CF e sono stati aggiunti altri campi obbligatori, quindi va direttamente all'inserimento
      this.insertUser()
    }

    const cfc = this.form.controls['fiscal_code'];
    let fiscalCode = null;
    try {
      fiscalCode = new CodiceFiscale(cfc.value);
    } catch {
      // la chiamata "new CodiceFiscale()" genera errore in caso di CF non valido
      this.notifier.showWarning('Attenzione', 'Codice fiscale non valido');
      return
    }
    const birthday = fiscalCode.birthday;
    const birthday_string = fiscalCode.year + '-' + ("0" + fiscalCode.month).slice(-2) + '-' + ("0" + fiscalCode.day).slice(-2);
    const gender = fiscalCode.gender;
    const birth_place = fiscalCode.birthplace.nome;
    const birth_province = fiscalCode.birthplace.prov;
    const age = (Math.abs(Date.now() - birthday.getTime()) / (1000 * 3600 * 24)) / 365.25;
    const isStudent = age < 19.5;
    // aggiungo alla form dei campi prepolati in automatico
    this.form.addControl('id_gender', new FormControl((gender == 'M') ? 1 : 0, Validators.required));
    this.form.addControl('birth_date', new FormControl(birthday_string, Validators.required));
    this.form.addControl('birth_place', new FormControl(birth_place, Validators.required));
    this.form.addControl('birth_province', new FormControl(birth_province, Validators.required));
    this.form.controls['id_gender'].markAsDirty();
    this.form.controls['birth_date'].markAsDirty();
    this.form.controls['birth_place'].markAsDirty();
    this.form.controls['birth_province'].markAsDirty();
    if (isStudent) {
      // nel caso di registrazione semplificata, valorizzo il nome con lo username, in quanto campo fondamentale per login e sessione
      this.form.addControl('name', new FormControl(this.form.controls['username'].value, Validators.required));
      this.form.controls['name'].markAsDirty();
      this.insertUser();
    } else {
      // se previsto, aggiunge nuovi campi da compilare
      this.form.addControl('name', new FormControl('', Validators.required));
      this.form.addControl('surname', new FormControl('', Validators.required));
      this.form.addControl('residence_cap', new FormControl('', Validators.required));
      this.form.addControl('residence_address', new FormControl('', Validators.required));
      this.form.addControl('residence_city', new FormControl('', Validators.required));
      this.form.addControl('residence_province', new FormControl('', Validators.required));
      this.form.addControl('phone', new FormControl('', Validators.required));
      this.form.addControl('notes', new FormControl(''));

      this.step2 = true;
    }
  }
  insertUser() {
    let dirtyFields = {};
    // ferma l'esecuzione se la form non è valida
    if (this.form.invalid) {
      this.notifier.showWarning('Attenzione', 'Form non valida');
      return;
    }
    if (!this.form.dirty) {
      this.notifier.showWarning('Attenzione', 'Nessuna modificare da salvare');
      return;
    }
    // nel caso di registrazione semplificata, valorizzo il nome con lo username, in quanto campo fondamentale per login e sessione
    this.form.addControl('name', new FormControl(this.form.controls['username'].value, Validators.required));
    this.form.controls['name'].markAsDirty();
    
    Object.keys(this.form.controls).forEach((c) => {
      let currentControl = this.form.controls[c];
      if (currentControl.dirty) {
        dirtyFields[c] = currentControl.value === true ? 1 : 
          currentControl.value === false ? 0 :
          currentControl.value;
      }
    });
    this.recaptchaSubscription = this.recaptchaV3Service
      .execute('registration')
      .subscribe((token) => {
        this.api.registration(dirtyFields, token).subscribe({
          next: (response) => {
            this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
            this.router.navigate(['/']);
          },
          error: (err) => {
            console.log(err);
            this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail);
          },
        });
      });
    return;
  }
  checkUniqueness(field: string, e: any) {
    const value = e?.target?.value?.toLowerCase();
    if (value.length < 6) return false;
    this.api
      .select(
        null,
        'users',
        [],
        [{ field: field, operator: '=', value: value }],
        [],
        { count: true }
      )
      .subscribe({
        next: (data) => {
          if (data['queryCount'] == 0) console.log(field + ' disponibile');
          else console.log(field + ' non disponibile');
        },
      });

    return true;
  }
}
