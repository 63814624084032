<div class="main">
    <zxing-scanner *ngIf="!scanCompleted" style="max-width: 80vw" (scanSuccess)="scanSuccess($event)"
        (camerasNotFound)="camerasNotFoundHandler($event)" (scanError)="scanErrorHandler($event)"></zxing-scanner>
    <div *ngIf="scanCompleted">
        <div *ngIf="!checkComplete">
            <!-- Verifica del QrCode -->
            <mat-spinner color="warn"></mat-spinner>
        </div>
        <div *ngIf="checkComplete" class="text-center">
            <!-- Risultato OK -->
            <div *ngIf="checkResult" class="checkResult resultOK">
                <i class="bi bi-hand-thumbs-up-fill mb-3" style="font-size: 60px"></i>
                <br /><b>{{ userEvent["title"] }}</b>
                <br />{{userEvent["date"] | date : "fullDate"}}
                <br />{{ userEvent["time"] * 1000 | date : "HH:mm" : "UTC" }} <br />{{userEvent["fullname"]}}
            </div>
            <!-- Risultato KO -->
            <div *ngIf="!checkResult" class="checkResult resultKO">
                <i class="bi bi-hand-thumbs-down-fill mb-3" style="font-size: 60px"></i>
                <br /><b>ACCREDITO<br />NON VALIDO</b>
            </div>
            <!-- Pulsante nuova scansione -->
            <span (click)="newScan()" style="cursor: pointer; padding: 20px; position: relative; top: 20px">
                Nuova scansione
            </span>
        </div>
    </div>
</div>