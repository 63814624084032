<div *ngIf="mainEvents.length" class="container" style="padding-bottom: 50px;">
    <h3 class="text-start">In evidenza <em *ngIf="search" class="h5"> (ricerca: "{{search}}")</em></h3>
    <p class="text-start">Scopri gli eventi principale</p>
    <div class="row">
        <div *ngFor="let event of mainEvents" class="col-md-4" style="padding: 10px;" routerLink="/evento/{{event.base_url_event}}">
            <div class="card_hoverable"><img class="d-lg-flex" src="{{event.main_image}}"
                    style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                <div style="padding: 15px;">
                    <h5><strong>{{event.title_event}}</strong></h5>
                    <p style="font-weight: bold;">{{event.date| date : 'fullDate'}} ore {{event.time*1000 | date:
                        'HH:mm':'UTC'}}</p>
                    <p>{{event.description_event}}</p>
                    <div class="text-end" style="min-width: 100%;">
                        <a class="detail_btn btn btn-primary text-center" routerLink="/evento/{{event.base_url_event}}">Maggiori informazioni</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="nextEvents.length" class="container" style="padding-bottom: 50px;">
    <h3 class="text-start">Prossimi eventi <em *ngIf="search" class="h5"> (ricerca: "{{search}}")</em></h3>
    <p class="text-start">Scopri cosa c'è in programma</p>
    <div class="row">
        <div *ngFor="let event of nextEvents" class="col-12 col-sm-6 col-md-4 col-lg-3" style="padding: 10px;" routerLink="/evento/{{event.base_url_event}}">
            <div class="card_hoverable"><img src="{{event.main_image}}"
                    style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                <div style="padding: 15px;">
                    <h5><strong>{{event.title_event}}</strong></h5>
                    <p style="font-weight: bold;">{{event.date| date : 'fullDate'}} ore {{event.time*1000 | date :
                        'HH:mm'}}</p>
                    <p>{{event.description_event}}</p>
                    <div class="text-end" style="min-width: 100%;">
                        <a class="detail_btn btn btn-primary text-center" routerLink="/evento/{{event.base_url_event}}">Maggiori informazioni</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h5 (click)="moreRecords('nextEvents')" *ngIf="offsetNext+limitNext<nextEventsCount && !loadingNext"
        class="text-center" style="text-decoration: underline; margin-top: 10px; cursor: pointer;">Altri eventi</h5>
    <h5 *ngIf="loadingNext" class="text-center">Caricamento...</h5>
</div>
<div *ngIf="closedEvents.length" class="container" style="padding-bottom: 50px;">
    <h3 class="text-start">Eventi completati <em *ngIf="search" class="h5"> (ricerca: "{{search}}")</em></h3>
    <p class="text-start">Scopri tutti gli eventi già terminati</p>
    <div class="row">
        <div *ngFor="let event of closedEvents" class="col-12 col-sm-6 col-md-4 col-lg-3" style="padding: 10px;" routerLink="/evento/{{event.base_url_event}}">
            <div class="card_hoverable"><img src="{{event.main_image}}"
                    style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                <div style="padding: 15px;">
                    <h5><strong>{{event.title_event}}</strong></h5>
                    <p style="font-weight: bold;">{{event.date| date : 'fullDate'}} ore {{event.time*1000 | date :
                        'HH:mm'}}</p>
                    <p>{{event.description_event}}</p>
                    <div class="text-end" style="min-width: 100%;">
                        <a class="detail_btn btn btn-primary text-center" routerLink="/evento/{{event.base_url_event}}">Maggiori informazioni</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h5 (click)="moreRecords('closedEvents')" *ngIf="offsetClosed+limitClosed<closedEventsCount && !loadingClosed"
        class="text-center" style="text-decoration: underline; margin-top: 10px; cursor: pointer;">Altri eventi</h5>
    <h5 *ngIf="loadingClosed" class="text-center">Caricamento...</h5>
</div>
<div class="container" style="padding-bottom: 50px;"
    *ngIf="loadedMain && !mainEvents.length && loadedClosed && !closedEvents.length && loadedNext && !nextEvents.length">
    <p>Nessun evento disponibile...</p>
</div>