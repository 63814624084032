import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { FiltersModel } from 'src/app/models/entity.model';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.css'
})
export class NewsComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private api: ApiService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) { }

  userData: UserModel;
  isLogged: boolean = false;
  articles = [];
  articlesCount: number = 0;
  limit = 6;
  offset = 0;
  loading = false;
  loadingError = false;
  baseUrl: string;
  metaInfo = [];

  ngOnInit() {
    if (this.route.snapshot.params.id)
      this.baseUrl = this.route.snapshot.params.id;
    this.userData = this.storage.getUserData();
    this.isLogged = this.userData?.admin != undefined;
    this.loadData();
  }

  loadData() {
    this.getArticles();
  }
  getArticles(mode?: 'append') {
    const _entity = 'categories_articles';
    const _fields = ['id_article', 'title_article', 'description_article', 'creation', 'main_image', 'base_url_article', 'record_type', 'external_link'];
    const _filters: FiltersModel[] = [{ field: 'active', operator: '=', value: 1 }];
    if (this.baseUrl) {
      _filters.push({
        field: 'base_url_category',
        operator: '=',
        value: this.baseUrl,
      });
    }
    const _sort = [{ field: 'creation', direction: 'desc' }];
    const _options = {
      distinct: true,
      limit: this.limit,
      offset: this.offset
    };
    this.api.pSelect(null, _entity, _fields, _filters, _sort, _options).subscribe({
      next: (data) => {
        if (mode == 'append')
          this.articles.push(...data);
        else
          this.articles = data;
        this.getExternalMeta();
        this.loading = false;
      },
      error: (err) => {
        this.loadingError = true;
        this.notifier.showError('Attenzione', 'Si è verificato un errore imprevisto.');
      }
    });
    const _countOption = { count: true };
    this.api.pSelect(null, _entity, _fields, _filters, _sort, _countOption).subscribe(
      data => {
        this.articlesCount = data[0]['queryCount'];
      });
  }
  getExternalMeta() {
    this.articles.slice()
      .filter(a => a['external_link'] == 0 || a['external_link'] == false)
      .forEach(a => {
        const _url = a['base_url_article'];
        try {
          this.api.get_meta_from_url(_url).subscribe({
            next: (data) => {
              this.metaInfo[a['id_article']] = {
                'url': _url,
                'title': data['og:title'],
                'image': data['og:image'],
                'description': data['og:description']
              }
            },
            error: (err) => {
              this.notifier.showError(err.name, err.message);
            }
          });
        } catch { }
      });
  }
  moreRecords(type) {
    // 'nextEvents'
    switch (type) {
      case 'articles': {
        this.loading = true;
        this.offset += this.limit;
        this.getArticles('append');
        break;
      }
      default: break;
    }
  }
  cardLink(article) {
    if (article.record_type == 'article') {
      if (article.external_link == 0) {
        window.open(article.base_url_article);
      } else {
        window.location.href = '/articolo/' + article.base_url_article;
      }
    } else {
      window.location.href = '/evento/' + article.base_url_article
    }
  }
}
