<button type="button" mat-button (click)="navigation_back()"><mat-icon>chevron_left</mat-icon>Indietro</button>
<p *ngIf="!eventLoaded">Caricamento...</p>
<div *ngIf="eventLoaded" class="container text-center" style="margin-top: 20px">
  <img src="{{ event['main_image'] }}" style="max-width: 800px; width: 100%" />
  <div class="text-start" style="width: 100%; max-width: 800px; margin: 0 auto; font-weight: bold">
    <h3 style="padding-top: 24px">
      <strong>{{ event["title"] }}</strong>
    </h3>
    <p style="font-weight: 400">{{ event["description"] }}</p>
    <h4 style="font-weight: bold">Data e ora</h4>
    <p style="font-weight: 400">{{ event["date"] | date : "fullDate" }} -
      ore {{ event["time"] * 1000 | date : "HH:mm" : "UTC" }}</p>
    <h4 style="font-weight: bold">Località</h4>
    <p style="font-weight: 400">{{ event["place"] }}</p>
    <p style="font-weight: 400">{{ event["address"] }} {{ event["city"] }} {{ event["province"] }}</p>
    <google-map *ngIf="showMap" [center]="center" [zoom]="zoom" [options]="options">
      <map-marker *ngFor="let marker of markers; let ix = index" [position]="marker.position" [label]="marker.label"
        [title]="marker.title" [options]="marker.options" (mapClick)="openInfoWindow(ix, marker.info)">
      </map-marker>
      <map-info-window>
        <strong>{{ event["title"] }}</strong>
        <br />
        <a href="https://www.google.com/maps/dir/?api=1&destination={{event['lat']}}+{{ event['lng'] }}&travelmode=driving"
          target="_blank">Indicazioni stradali</a>
      </map-info-window>
    </google-map>
    <h4 style="font-weight: bold; padding-top: 24px">
      Informazioni sull'evento
    </h4>
    <br />
    <p style="font-weight: 400" [innerHTML]="event['body']"></p>
    <br /><br />
    <div *ngIf="categories.length">
      <h4 style="font-weight: bold">Categorie dell'evento</h4>
      <div class="row" style="padding: 15px">
        <a *ngFor="let category of categories" class="col-6 col-sm-4 col-md-3"
          style="margin-bottom: 10px; text-decoration: none;" routerLink="/eventi/{{ category.base_url_category }}">
          <div class="d-sm-flex justify-content-sm-center align-items-sm-center"
            style="min-width: 100%;max-width: 100%;padding: 6px;border-radius: 20px;background-color: #00000065; border: 1px solid rgba(33,37,41,0.49);min-height: 100%;max-height: 100%;">
            <h5 class="text-center d-sm-flex" style="margin-bottom: 0px; color: white !important;">
              {{category.description_category}}
            </h5>
          </div>
        </a>
      </div>
    </div>
    <div *ngIf="reviews.length">
      <h4 style="font-weight: bold">Recensioni</h4>
      <div *ngFor="let review of reviews"
        style="margin: 10px 0px; padding: 20px; border: 1px solid #ddd; border-radius: 5px;">
        <h5 style="margin: 0px">{{ review["title"] }}</h5>
        <br />
        <span style="font-weight: normal">{{ review["body"] }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="lastEvents.length" class="container" style="padding-top: 50px">
  <h3 class="text-start">Ultimi eventi</h3>
  <p class="text-start">Scopri cosa c'è in programma</p>
  <div class="row">
    <div *ngFor="let event of lastEvents" class="col-12 col-sm-6 col-md-4 col-lg-3" style="padding: 10px"
      (click)="customRoute('/evento/'+event.base_url)">
      <div class="card_hoverable">
        <img src="{{ event.main_image }}" style="min-width: 100%; max-width: 100%; border-radius: 15px 15px 0px 0px;" />
        <div style="padding: 15px">
          <h5><strong>{{ event.title }}</strong></h5>
          <p style="font-weight: bold">{{ event.date }} alle {{event.time*1000 | date: 'HH:mm':'UTC'}}</p>
          <p>{{ event.description }}</p>
          <div class="text-end" style="min-width: 100%">
            <a class="detail_btn btn btn-primary text-center" href="/evento/{{ event.base_url }}">Maggiori
              informazioni</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="eventLoaded && !eventRegistered" class="buy_div">
  <!-- DEBUG disponibilità evento
  evento chiuso : {{event['closed']}}<br />
  studente : {{ isStudent }}<br />
  socio : {{ isPartner }}<br />
  <br />
  disponibili : {{ event["available_seats"] }}<br />
  riservati studenti : {{ event["reserved_student_seats"] }}<br />
  riservati soci : {{ event["reserved_partner_seats"] }}<br />
  <br />
  utenti prenotati : {{ event["count_users"] }}<br />
  studenti prenotati : {{ event["count_students"] }}<br />
  soci prenotati : {{ event["count_partners"] }}<br />
  <br />
  disponibili soci : {{ event["available_partners"] }} disponibili studenti : {{ event["available_students"] }}
  {{event['closed'] == 0}}
  {{isStudent && event['available_students'] <= 0}}
  {{isPartner && event['available_partners'] <= 0}}
  {{event['closed'] == 0 && (isStudent && event['available_students'] <= 0) || (isPartner && event['available_partners'] <= 0)}} -->
  <div *ngIf="event['closed'] == 1">
    <h2>Evento terminato</h2>
  </div>
  <div *ngIf="
      (event['closed'] == 0 && isStudent && event['available_students'] <= 0) ||
      (isPartner && event['available_partners'] <= 0)
    ">
    <h2>Disponibilità esaurita</h2>
  </div>
  <div *ngIf="event['closed'] == 0">
    <h1 *ngIf="isStudent && event['available_students'] > 0">Gratuito</h1>
    <h1 *ngIf="isPartner && event['available_partners'] > 0 && event['price']">
      {{ event["price"] | currency : "EUR" }}
    </h1>
    <a *ngIf="
        isLogged &&
        ((isStudent && event['available_students'] > 0) ||
          (isPartner && event['available_partners'] > 0 && event['price']))
      " class="btn btn-primary" (click)="checkout()"
      style="color: var(--bs-btn-color); background: rgb(3, 202, 13); width: 100%;">
      Prenota
    </a>
    <a *ngIf="!isLogged" class="btn btn-primary" routerLink="/login"
      style="color: var(--bs-btn-color); background: rgb(253, 13, 13); width: calc(100% + 20px); height: 100%; margin: -10px -20px; border: 0px;">
      Effettua il login<br />per prenotare
    </a>
  </div>
</div>
<div *ngIf="eventRegistered" class="buy_div">
  <h3>Evento già acquistato</h3>
</div>