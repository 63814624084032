import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-eventi',
  // standalone: true,
  // imports: [],
  templateUrl: './eventi.component.html',
  styleUrl: './eventi.component.css',
})
export class EventiComponent {
  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private api: ApiService
  ) { }

  baseUrl: string;
  search: string;
  userData: UserModel;
  isLogged: boolean = false;
  category = null;
  mainEvents = [];
  nextEvents = [];
  closedEvents = [];
  nextEventsCount: number = 0;
  closedEventsCount: number = 0;
  limitNext = 4;
  limitClosed = 4;
  offsetNext = 0;
  offsetClosed = 0;
  loadedMain = false;
  loadedNext = false;
  loadedClosed = false;
  loadingNext = false;
  loadingClosed = false;
  lastEvents = [];

  ngOnInit() {
    if (this.route.snapshot.params.id)
      this.baseUrl = this.route.snapshot.params.id;
    if (this.route.snapshot.params.search)
      this.search = decodeURIComponent(this.route.snapshot.params.search);
    this.userData = this.storage.getUserData();
    this.isLogged = this.userData?.admin != undefined;
    this.loadData();
  }

  loadData() {
    // this.getLastEvents();
    this.getNextEvents();
    this.getClosedEvents();
    this.getMainEvents();
  }
  getMainEvents() {
    const _entity = 'categories_events';
    const _fields = [
      'title_event',
      'description_event',
      'date',
      'time',
      'main_image',
      'base_url_event',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active_event', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 0 },
      // { field: 'sponsored', operator: '=', value: 1 },
    ];
    if (this.baseUrl) {
      _filters.push({
        field: 'base_url_category',
        operator: '=',
        value: this.baseUrl,
      });
    } else if (this.search) {
      _filters.push({ field: 'search', operator: 'like', value: this.search });
    }
    const _sort = [{ field: 'date', direction: 'asc' }];
    const _options = {
      distinct: true,
      limit: 3,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.mainEvents = data;
        this.loadedMain = true;
      });
  }
  getClosedEvents(mode?: 'append') {
    const _entity = 'categories_events';
    const _fields = [
      'title_event',
      'description_event',
      'date',
      'time',
      'main_image',
      'base_url_event',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active_event', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 1 },
      { field: 'date', operator: '<', value: moment().format('YYYY-MM-DD') },
    ];
    if (this.baseUrl) {
      _filters.push({
        field: 'base_url_category',
        operator: '=',
        value: this.baseUrl,
      });
    } else if (this.search) {
      _filters.push({ field: 'search', operator: 'like', value: this.search });
    }
    const _sort = [{ field: 'date', direction: 'desc' }];
    const _options = {
      distinct: true,
      limit: this.limitNext,
      offset: this.offsetNext,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        if (mode == 'append') this.closedEvents.push(...data);
        else {
          this.closedEvents = data;
          this.loadedClosed = true;
        }
        this.loadingClosed = false;
      });
    const _countOption = { distinct: true, count: true };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _countOption)
      .subscribe((data) => {
        this.closedEventsCount = data[0]['queryCount'];
      });
  }
  getNextEvents(mode?: 'append') {
    const _entity = 'categories_events';
    const _fields = [
      'title_event',
      'description_event',
      'date',
      'time',
      'main_image',
      'base_url_event',
    ];
    const _filters: FiltersModel[] = [
      { field: 'active_event', operator: '=', value: 1 },
      { field: 'closed', operator: '=', value: 0 },
      { field: 'date', operator: '>', value: moment().format('YYYY-MM-DD') },
    ];
    if (this.baseUrl) {
      _filters.push({
        field: 'base_url_category',
        operator: '=',
        value: this.baseUrl,
      });
    } else if (this.search) {
      _filters.push({ field: 'search', operator: 'like', value: this.search });
    }
    const _sort = [{ field: 'date', direction: 'asc' }];
    const _options = {
      distinct: true,
      limit: this.limitNext,
      offset: this.offsetNext,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        if (mode == 'append') this.nextEvents.push(...data);
        else {
          this.nextEvents = data;
          this.loadedNext = true;
        }
        this.loadingNext = false;
      });
    const _countOption = { distinct: true, count: true };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _countOption)
      .subscribe((data) => {
        this.nextEventsCount = data[0]['queryCount'];
      });
  }
  moreRecords(type) {
    // 'nextEvents'
    switch (type) {
      case 'nextEvents': {
        this.loadingNext = true;
        this.offsetNext += this.limitNext;
        this.getNextEvents('append');
        break;
      }
      case 'closedEvents': {
        this.loadingClosed = true;
        this.offsetClosed += this.limitClosed;
        this.getClosedEvents('append');
        break;
      }
      default:
        break;
    }
  }
}
