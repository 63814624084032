<div *ngIf="innerWidth <= 760 || mobile" class="container text-center"
    style="margin-top: 25px;padding-left: 30px;padding-right: 30px;">
    <div *ngIf="!submenu && !isLogged">
        <button *ngIf="!this.isLogged" class="btn btn-primary mobile_home_button" role="button"
            routerLink="/login">LOGIN</button>
        <button *ngIf="!this.isLogged" class="btn btn-primary mobile_home_button" role="button"
            routerLink="/iscriviti">ISCRIVITI</button>
        <h1 style="color: var(--bs-body-bg);margin-top: 25px;">
            Laboratori di cucina, incontri con il mondo del cibo, notizie ed eventi gastronomici. Qualità, Sostenibilità
            e Salute.
        </h1>
        <button class="btn btn-primary mobile_home_button" role="button" (click)="submenu=true">DAI UN'OCCHIATA</button>
    </div>
    <div *ngIf="(submenu || isLogged)">
        <div *ngIf="!categories_view">
            <div *ngIf="!chi_siamo">
                <button class="btn btn-primary mobile_home_button" role="button" routerLink="/eventi">EVENTI</button>
                <button class="btn btn-primary mobile_home_button" role="button" routerLink="/news">NEWS</button>
                <button class="btn btn-primary mobile_home_button" role="button" (click)="categories_view=true">AREE TEMATICHE</button>
                <!-- <button class="btn btn-primary mobile_home_button" role="button"
                    routerLink="/news/prodotti">PRODOTTI</button>
                <button class="btn btn-primary mobile_home_button" role="button"
                    routerLink="/news/tecniche-di-cucina">TECNICHE
                    DI CUCINA</button> -->
                <button class="btn btn-primary mobile_home_button" role="button" (click)="chi_siamo=true">CHI SIAMO</button>
            </div>
            <div *ngIf="chi_siamo" class="chi-siamo-div">
                <h2>Insieme, ora, per un cibo ed un futuro possibili</h2>
                <p>Cibofuturo è un opportunità di riportare il cibo al centro dell'attenzione.
                    E' laboratori del gusto. seminari, tavole rotonde, incontri diretti con prodotti, produttori e
                    selezionatori d'eccellenza.
                    E' tutoring gratuito riservato agli allievi di sala e di cucina delle nostre scuole alberghiere.
                    E' rete di conoscenze e condivisione di esperienze gastronomiche, di consapevolezza sui temi
                    sostenibilità e salute,
                    Il cibo giusto al centro dell'attenzione è una via al futuro.</p>
                <span (click)="chi_siamo=false" style="cursor: pointer; display: flex; margin-bottom: 12px;">
                    <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48px" height="64px" viewBox="0 0 400.004 400.004" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"></path> </g> </g></svg>
                </span>
            </div>
        </div>
        <div *ngIf="categories_view">
            <button *ngFor="let category of categories" class="btn btn-primary mobile_home_button" role="button"
                routerLink="/eventi/{{category.base_url}}">{{category.description}}</button>
            <span (click)="categories_view=false" style="cursor: pointer; display: flex; margin-bottom: 12px;">
                <svg fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48px" height="64px" viewBox="0 0 400.004 400.004" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M382.688,182.686H59.116l77.209-77.214c6.764-6.76,6.764-17.726,0-24.485c-6.764-6.764-17.73-6.764-24.484,0L5.073,187.757 c-6.764,6.76-6.764,17.727,0,24.485l106.768,106.775c3.381,3.383,7.812,5.072,12.242,5.072c4.43,0,8.861-1.689,12.242-5.072 c6.764-6.76,6.764-17.726,0-24.484l-77.209-77.218h323.572c9.562,0,17.316-7.753,17.316-17.315 C400.004,190.438,392.251,182.686,382.688,182.686z"></path> </g> </g></svg>
            </span>
        </div>
    </div>
</div>
<div *ngIf="innerWidth > 760 && !mobile">
    <div class="container" style="padding-top: 50px;">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <h2>Insieme, ora, per un cibo ed un futuro possibili</h2>
                <p>Cibofuturo è un opportunità di riportare il cibo al centro dell'attenzione.
                    E' laboratori del gusto. seminari, tavole rotonde, incontri diretti con prodotti, produttori e
                    selezionatori d'eccellenza.
                    E' tutoring gratuito riservato agli allievi di sala e di cucina delle nostre scuole alberghiere.
                    E' rete di conoscenze e condivisione di esperienze gastronomiche, di consapevolezza sui temi
                    sostenibilità e salute,
                    Il cibo giusto al centro dell'attenzione è una via al futuro.<br><br></p>
            </div>
            <div class="col-md-12 col-lg-6"><img src="https://cibofuturo.b-cdn.net/images/sfondo.jpg"
                    style="min-width: 100%;max-width: 100%;"></div>
        </div>
    </div>
    <div *ngIf="mainEvents.length" class="container" style="padding-top: 50px;">
        <h3 class="text-start">In evidenza</h3>
        <p class="text-start">Scopri gli eventi principale</p>
        <div class="row">
            <div *ngFor="let event of mainEvents" class="col-md-4" style="padding: 10px;"
                routerLink="/evento/{{event.base_url}}">
                <div class="card_hoverable">
                    <img class="d-lg-flex" src="{{event.main_image}}"
                        style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                    <div style="padding: 15px;">
                        <h5><strong>{{event.title}}</strong></h5>
                        <p style="font-weight: bold;">{{event.date| date : 'fullDate'}} ore {{event.time*1000 |
                            date:'HH:mm':'UTC'}}</p>
                        <p>{{event.description}}</p>
                        <div class="text-end" style="min-width: 100%;">
                            <a class="detail_btn btn btn-primary text-center"
                                routerLink="/evento/{{event.base_url}}">Maggiori informazioni</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding-top: 40px;">
        <h3 class="text-start">Aree Tematiche</h3>
        <div class="row" style="padding: 15px;">
            <a *ngFor="let category of categories" class="col-6 col-sm-4 col-md-3 col-lg-2"
                style="margin-bottom: 10px; text-decoration: none;" routerLink="/eventi/{{category.base_url}}">
                <div class="d-sm-flex justify-content-sm-center align-items-sm-center"
                    style="min-width: 100%;max-width: 100%;padding: 6px;border-radius: 20px;background-color: #00000065; border: 1px solid rgba(33,37,41,0.49);min-height: 100%;max-height: 100%;">
                    <h5 class="text-center d-sm-flex" style="margin-bottom: 0px; color: white !important;">
                        {{category.description}}
                    </h5>
                </div>
            </a>
        </div>
    </div>
    <div *ngIf="nextEvents.length" class="container" style="padding-top: 50px;">
        <h3 class="text-start">Prossimi eventi</h3>
        <p class="text-start">Scopri cosa c'è in programma</p>
        <div class="row">
            <div *ngFor="let event of nextEvents" class="col-12 col-sm-6 col-md-4 col-lg-3" style="padding: 10px;"
                routerLink="/evento/{{event.base_url}}">
                <div class="card_hoverable">
                    <img src="{{event.main_image}}"
                        style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                    <div style="padding: 15px;">
                        <h5><strong>{{event.title}}</strong></h5>
                        <p style="font-weight: bold;">{{event.date| date : 'fullDate'}} ore {{event.time*1000 | date :
                            'HH:mm'}}</p>
                        <p>{{event.description}}</p>
                        <div class="text-end" style="min-width: 100%;">
                            <a class="detail_btn btn btn-primary text-center"
                                routerLink="/evento/{{event.base_url}}">Maggiori informazioni</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="lastEvents.length" class="container" style="padding-top: 50px;">
        <h3 class="text-start">Ultimi Eventi Inseriti</h3>
        <p class="text-start">Alcuni dei nostri ultimi eventi</p>
        <div class="row">
            <div *ngFor="let event of lastEvents" class="col-md-4" style="padding: 10px;"
                routerLink="/evento/{{event.base_url}}">
                <div class="card_hoverable"><img class="d-lg-flex" src="{{event.main_image}}"
                        style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                    <div style="padding: 15px;">
                        <h5><strong>{{event.title}}</strong></h5>
                        <p style="font-weight: bold;">{{event.date| date : 'fullDate'}} ore {{event.time*1000 | date:
                            'HH:mm':'UTC'}}</p>
                        <p>{{event.description}}</p>
                        <div class="text-end" style="min-width: 100%;">
                            <a class="detail_btn btn btn-primary text-center"
                                routerLink="/evento/{{event.base_url}}">Maggiori informazioni</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="lastArticles.length" class="container" style="padding-top: 50px;">
        <h3 class="text-start">CiboFuturo notizie</h3>
        <p class="text-start">Le ultime 3 news inserite</p>
        <div class="row">
            <div *ngFor="let article of lastArticles" class="col-12 col-sm-6 col-md-4 col-lg-4" style="padding: 10px;"
                routerLink="/articolo/{{article.base_url}}">
                <div class="card_hoverable"><img src="{{article.main_image}}"
                        style="min-width: 100%;max-width: 100%;border-radius: 15px 15px 0px 0px;">
                    <div style="padding: 15px;">
                        <h5><strong>{{article.title}}</strong></h5>
                        <p style="font-weight: bold;">{{article.creation| date : 'fullDate'}}</p>
                        <p>{{article.description}}</p>
                        <div class="text-end" style="min-width: 100%;">
                            <a class="detail_btn btn btn-primary text-center"
                                routerLink="/articolo/{{article.base_url}}">Leggi articolo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>