import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable, throwError, map } from 'rxjs';
// import { catchError, retry } from 'rxjs/operators';
// import { StorageService } from './storage.service';
import { FiltersModel } from '../models/entity.model';
import { environment } from '../../environment/environment';

// url contenuta nel file environment
const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({
    'accept': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // constructor(private router: Router, private http: HttpClient, private storage: StorageService) { }
  constructor(private http: HttpClient) { }

  delete_user() {
    return this.http.get<any>(API_URL + '/delete_user/');
  }
  get_meta_from_url(url: string) {
    const data = {
      "link": url
    };
    return this.http.post<any>(API_URL + '/get_meta_from_url/', data, httpOptions);
  }

  getTables() {
    return this.http.get<any>(API_URL + '/tables/');
  }

  getTable(table: string) {
    const data = {
      "table": table
    };
    return this.http.post<any>(API_URL + '/table/', data, httpOptions);
  }

  testTable() {
    const tables = this.getTables().subscribe(
      val => {
        console.log(val);
        console.log(val[0]);
        const table = this.getTable(val[0]).subscribe(
          t => {
            console.log(t);
          }
        );
      }
    );
  }

  confirmation(code: string, token: string) {
    const data = {
      "code": code,
      "g_recaptcha_response": token
    };

    return this.http.post<any>(API_URL + '/confirm_email/', data, httpOptions);
  }

  select(dataModel: any, table: string, fields: string[], filters: FiltersModel[], sort: any[], options: {}) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters,
      "sort": sort,
      "options": options ? options : {}
    };

    return this.http.post<typeof dataModel>(API_URL + '/select/', data, httpOptions);
  }

  pSelect(dataModel: any, table: string, fields: string[], filters: FiltersModel[], sort: any[], options: {}) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters,
      "sort": sort,
      "options": options ? options : {}
    };

    return this.http.post<typeof dataModel>(API_URL + '/pselect/', data, httpOptions);
  }

  registration(fields: {}, token: string) {
    const data = {
      "fields": fields,
      "g_recaptcha_response": token
    };

    return this.http.post(API_URL + '/registration/', data, httpOptions);
  }

  insert(dataModel: any, table: string, fields: {}) {
    const data = {
      "table": table,
      "fields": fields
    };

    return this.http.post<typeof dataModel>(API_URL + '/insert/', data, httpOptions);
  }

  update(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/update/', data, httpOptions);
  }

  upsert(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/upsert/', data, httpOptions);
  }

  createOrder(user: number, event: number) {
    const data = {
      "user": user,
      "event": event
    };

    return this.http.post(API_URL + '/create_order_stripe/', data, httpOptions);
  }

  confirmOrder(code: string) {
    const data = {
      "code": code
    };

    return this.http.post(API_URL + '/confirm_order_stripe/', data, httpOptions);
  }

  cancelOrder(code: string) {
    const data = {
      "code": code
    };

    return this.http.post(API_URL + '/cancel_order_stripe/', data, httpOptions);
  }
}
