import { Component, ElementRef, ViewChild } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UserModel } from './models/user.model';
import { FiltersModel } from './models/entity.model';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('menuButton', { static: true }) menuButton: ElementRef;
  @ViewChild('menuDiv', { static: true }) menuDiv: ElementRef;

  role: string = 'user';
  searchInput: string = '';
  menu = [
    {
      type: 'item',
      routerLink: '/',
      icon: 'home',
      title: 'Home',
      role: 'user',
    },
    {
      type: 'item',
      routerLink: '/assistenze',
      icon: 'handshake',
      title: 'Assistenze',
      role: 'user',
    },
    {
      type: 'item',
      routerLink: '/assenze',
      icon: 'work_off',
      title: 'Assenze',
      role: 'user',
    },
    {
      type: 'item',
      routerLink: '/segnalazioni',
      icon: 'playlist_add_check',
      title: 'Segnalazioni',
      role: 'user',
    },
    {
      type: 'item',
      routerLink: '/upload',
      icon: 'cloud_upload',
      title: 'Upload',
      role: 'admin',
    },
    {
      type: 'dropdown',
      icon: 'dog',
      title: 'Anagrafiche',
      role: 'admin',
      items: [
        {
          type: 'item',
          routerLink: '/reti',
          icon: 'business',
          title: 'Reti',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/istituti',
          icon: 'school',
          title: 'Istituti',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/allievi',
          icon: 'child_care',
          title: 'Allievi',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/attivita',
          icon: 'edit_calendar',
          title: 'Attività',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/operatori',
          icon: 'people_alt',
          title: 'Operatori',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/anni',
          icon: 'refresh',
          title: 'Anni',
          role: 'admin',
        },
      ],
    },
    {
      type: 'dropdown',
      icon: 'report',
      title: 'Report',
      role: 'admin',
      items: [
        {
          type: 'item',
          routerLink: '/report/istituto-mese',
          icon: 'grid_on',
          title: 'Istituto mese',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/report/operatore-mese',
          icon: 'grid_on',
          title: 'Operatore mese',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/report/istituto-settimana',
          icon: 'grid_on',
          title: 'Istituto settimana',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/report/operatore-settimana',
          icon: 'grid_on',
          title: 'Operatore settimana',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/report/contratto-settimana',
          icon: 'grid_on',
          title: 'Contratto settimana',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/report/sostituzione-settimana',
          icon: 'grid_on',
          title: 'Sostituzione settimana',
          role: 'admin',
        },
        {
          type: 'item',
          routerLink: '/report/settimane-incomplete',
          icon: 'grid_on',
          title: 'Settimane incomplete',
          role: 'admin',
        },
      ],
    },
    {
      type: 'item',
      routerLink: '/statistiche',
      icon: 'leaderboard',
      title: 'Statistiche',
      role: 'user',
    },
  ];

  title = 'CiboFuturo';
  loggedIn: boolean = false;
  termsAndConditions: boolean = false;
  admin: boolean = false;
  opened: boolean;
  events: string[] = [];
  username: string = '';
  mobile: boolean = false;
  userData: UserModel;
  categories = [];

  constructor(private storage: StorageService, private router: Router, private api: ApiService) {
    this.router.events.subscribe((ev) => {
      // a ogni cambio pagina, se il menu è aperto, lo chiude (funziona con BS e la classe 'show' del navbar)
      if (this.menuDiv.nativeElement.classList.contains('show')) {
        this.menuButton.nativeElement.click();
      }
      if (ev instanceof NavigationEnd) {
        // recupera eventuale parametro "mobile" settato solo da app mobile
        this.mobile = this.storage.getMobile() == 'true' ? true : false;
        if(this.mobile) {
          document.body.classList.add('mobile_body');
        }
        // recupera e controlla il ruolo dell'utente
        this.userData = this.storage.getUserData();
        if (this.userData != null && this.userData != undefined) {
          this.loggedIn = this.userData?.admin != undefined;
          this.role = this.storage.getRole();
          this.username = this.storage.getUserUsername();
          this.termsAndConditions = this.storage.getUserTermsAndConditions();
          this.admin = this.userData.admin ? true : false;
        } else {
          this.loggedIn = false;
        }
      }
    });
  }

  ngOnInit() {
    this.getCategories();
  }
  getCategories() {
    const _entity = 'categories';
    const _fields = ['description', 'base_url'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'articles', operator: '=', value: 0 }
    ];
    const _sort = [{ field: 'sort', direction: 'asc' }];
    const _options = {};
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.categories = data;
      });
  }

  searchEvents() {
    window.location.href = '/eventi/ricerca/' + encodeURIComponent(this.searchInput);
  }
}
