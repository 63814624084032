import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  setToken(data: any) {
    sessionStorage.setItem('token', data.access_token);
    if (data.refresh_token)
      sessionStorage.setItem('refreshToken', data.refresh_token);
    return true;
  }

  setMobileFlag() {
    sessionStorage.setItem('mobile', 'true');
  }

  isValidToken(token): boolean {
    return true;
  }

  decodeToken(token): UserModel {
    const decodedToken = jwt_decode(token);
    const userData: UserModel = {
      username: decodedToken['username'],
      name: decodedToken['name'],
      active: decodedToken['active'],
      admin: decodedToken['admin'],
      student: decodedToken['student'],
      terms_and_conditions: decodedToken['terms_and_conditions'],
    };
    return userData;
  }

  getTokenData(): UserModel {
    // recupera il token
    const token = this.getToken();
    // controlla se il token è valido
    if (!this.isValidToken(token)) {
      return null;
    }
    // decodifica il token
    const userData = this.decodeToken(token);
    if (userData) {
      return userData;
    }
    return null;
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  getMobile() {
    return sessionStorage.getItem('mobile');
  }

  getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
  }

  getUserData() {
    const token = this.getToken();
    if (token != null && token != undefined)
      return this.decodeToken(token);
    else
      return null;
  }

  getRole() {
    const userData: UserModel = this.getUserData();
    if (userData != null && userData != undefined) {
      if (userData?.admin) return 'admin';
      if (userData?.student) return 'student';
      return 'partner';
    } else {
      return null;
    }
  }

  getUserUsername(): string {
    return 'TBD-NOMINATIVO';
  }

  getUserTermsAndConditions(): boolean {
    const userData: UserModel = this.getUserData();
    return userData.terms_and_conditions;
  }

  deleteToken() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    return true;
  }

  createCart(productId: number) {
    sessionStorage.setItem('cart', productId.toString());
    return true;
  }

  getCart() {
    return sessionStorage.getItem('cart');
  }

  deleteCart() {
    sessionStorage.removeItem('cart');
    return true;
  }
}
