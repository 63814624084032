import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltersModel } from 'src/app/models/entity.model';
import { UserModel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-articolo',
  templateUrl: './articolo.component.html',
  styleUrl: './articolo.component.css'
})
export class ArticoloComponent implements OnInit {
  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
  ) { }

  baseUrl: string;
  userData: UserModel;
  isLogged: boolean = false;
  isStudent: boolean = false;
  isPartner: boolean = false;
  lastArticles = [];
  article = {};
  articleLoaded = false;
  categories = [];

  ngOnInit() {
    this.baseUrl = this.route.snapshot.params.id;
    this.loadData();
    this.userData = this.storage.getUserData();
    if (this.userData) {
      // this.isLogged = this.userData?.admin != undefined;
      this.isLogged = true;
      this.isStudent = this.userData?.student;
      this.isPartner = !this.userData?.student;
    }
  }

  loadData() {
    this.getArticle();
    this.getLastArticles();
  }
  getArticle() {
    const _entity = 'articles';
    const _fields = ['id', 'title', 'description', 'body', 'main_image', 'base_url', 'creation'];
    const _filters: FiltersModel[] = [
      { field: 'base_url', operator: '=', value: this.baseUrl },
      { field: 'active', operator: '=', value: 1 }
    ];
    const _sort = [];
    const _options = {};
    this.api.pSelect(null, _entity, _fields, _filters, _sort, _options).subscribe(
      data => {
        if (data.length == 0) {
          this.router.navigate(['/']);
        }
        this.article = data[0];
        this.getCategoriesList();
        this.articleLoaded = true;
      });
  }
  getCategoriesList() {
    const _entity = 'categories_articles';
    const _fields = ['description_category', 'base_url_category'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'id_article', operator: '=', value: this.article['id'] },
      { field: 'record_type', operator: '=', value: 'article' },
      // gestione del caso di evento senza categoria
      { field: 'id_category', operator: '>', value: 0 },
    ];
    const _sort = [];
    const _options = {
      distinct: true,
    };
    this.api
      .pSelect(null, _entity, _fields, _filters, _sort, _options)
      .subscribe((data) => {
        this.categories = data;
      });
  }
  getLastArticles() {
    const _entity = 'categories_articles';
    const _fields = ['title_article', 'description_article', 'creation', 'main_image', 'base_url_article', 'record_type'];
    const _filters: FiltersModel[] = [
      { field: 'active', operator: '=', value: 1 },
      { field: 'base_url_article', operator: '!=', value: this.baseUrl }
    ];
    const _sort = [{ field: 'creation', direction: 'desc' }];
    const _options = { distinct: true, limit: 3 };
    this.api.pSelect(null, _entity, _fields, _filters, _sort, _options).subscribe(
      data => {
        this.lastArticles = data;
      });
  }

  customRoute(url) {
    window.location.href = url;
  }
  navigation_back() {
    window.history.go(-1); return false;
  }
}
