import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EntityModel, LinkedModel, FieldsModel, FiltersModel } from 'src/app/models/entity.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormModalComponent } from 'src/app/shared/form-modal/form-modal.component';

@Component({
  selector: 'app-entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.css'],
})
export class EntityDetailComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private storage: StorageService,
    private notifier: NotifierService,
    public dialog: MatDialog
  ) { }

  private sub: Subscription;
  routeData = this.route.data['_value'];
  id: number;
  sourceName: string;
  page: EntityModel;
  fields_: FieldsModel;
  fieldsList: string[];
  visibleFields: FieldsModel[];
  visibleFieldsKV: FieldsModel[] = [];
  visibleFieldsList: string[] = [];
  fieldsGroups: string[];
  options: {};
  linked: LinkedModel[];
  filters: FiltersModel[];
  data: any = [];
  formSelectList: string[];
  dynamicLinked = [];
  linkedVisibleFieldsKV = [];
  dynamicAddLinked = [];
  isEditMode: boolean;
  dynamicData = [];
  formMode: ('insert' | 'update') = null;
  allFieldsList: string[];
  linkedAddShow: boolean[] = [];
  linkedAddEntries = [];
  linkedAddForm = [];
  role: string = null;

  ngOnInit() {
    this.role = this.storage.getRole();
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      // Nel caso in cui il parametro della url è non numerico intero
      if (typeof this.id !== "number" || isNaN(this.id) || this.id <= 0 || Math.trunc(this.id) != this.id) {
        // Reindirizzamento alla pagina 404
        this.router.navigate(['/404']);
      }
      // parametro source ricevuto in input da app-routing.module.ts
      this.sourceName = this.routeData['source'];
      this.page = new EntityModel(this.sourceName);
      // recupera la lista dei campi (tutti)
      this.fieldsList = this.page.getFieldsList();
      this.visibleFields = this.page.getVisibleFields();
      this.visibleFieldsKV = this.page.getVisibleFieldsKV();
      this.visibleFieldsList = this.page.getVisibleFieldsList();
      this.allFieldsList = this.page.getAllFieldsList();
      this.fieldsGroups = this.page.getFieldsGroups();
      this.options = this.page.getOptions();
      this.linked = this.page.getLinked();
      this.formSelectList = this.page.getFormSelectList();
      // applica il filtro per recuperare solo l'oggetto della pagina
      this.filters = [{ field: "id", operator: "=", value: this.id }];
      // Recupera la lista dei campi che hanno una form select
      this.formSelectList.forEach(fieldName => {
        this.dynamicData[fieldName] = {
          "form": this.page.getFormSelectByFieldName(fieldName),
          "data": []
        };
      });
      // carica i dati
      this.loadData();
    });
  }

  loadData() {
    // scarica i dati dell'entità dall'api
    this.api.select(typeof this.page.model, this.sourceName, this.allFieldsList, this.filters, [], {}).subscribe(
      data => {
        this.data = data[0];
      }
    );

    // scarica eventuali dati per le form select
    this.formSelectList.forEach(fieldName => {
      let options: {} = {};
      if (this.dynamicData[fieldName].form.select.distinct) {
        options = {
          'distinct': true
        };
      }

      let selectList = [];
      // id della select
      selectList.push(this.dynamicData[fieldName].form.select.id);
      // label della select
      selectList.push(this.dynamicData[fieldName].form.select.label);
      // eventuale chiave per un filtro dipendente da altra select
      if (this.dynamicData[fieldName].form.select.upSelectFilter) {
        selectList.push(this.dynamicData[fieldName].form.select.upSelectFilter.filterKey);
      }

      this.api.select(
        typeof {},
        this.dynamicData[fieldName].form.select.sourceName,
        selectList,
        this.dynamicData[fieldName].form.select.filters,
        [],
        options).subscribe(
          data => {
            this.dynamicData[fieldName]['data'] = data;
          }
        );
    });

    // scarica eventuali dati per le entità collegate
    this.linked?.forEach(obj => {
      this.linkedVisibleFieldsKV[obj.sourceName] = this.page.getLinkedVisibleFieldsKV(obj.sourceName);
      const fields: string[] = obj.fields.map(field => { return field.name; });
      // recupera i filtri dal modello
      const filters: FiltersModel[] = obj.filters.slice();
      // aggiunge il filtro dell'entità aperta
      filters.push({ field: obj.idJoin, operator: "=", value: this.id });
      // recupera gli oggetti associati
      this.api.select(typeof {}, obj.sourceName, fields, filters, [], {}).subscribe(
        data => {
          this.dynamicLinked[obj.sourceName] = data;
          // prepara la lista dei linked già associati
        }
      );
      // in caso di relazione "n a m"
      if (obj.select) {
        // recupera tutti i possibili valori dell'entità collegata per aggiungerle
        this.api.select(typeof {},
          obj.select.sourceName,
          [obj.select.id, obj.select.label],
          obj.select.filters, [], {}).subscribe(
            data => {
              this.dynamicData[obj.select.sourceName] = {
                "form": null,
                "data": data
              };
            }
          );
      }
      // in caso di relazioni "1 a n"
      if (obj.addFields) {
        obj.addFields.forEach(f => {
          if (f.form?.select) {
            // recupera tutti i possibili valori dell'entità collegata per aggiungerle
            this.api.select(typeof {},
              f.form.select.sourceName,
              [f.form.select.id, f.form.select.label],
              f.form.select.filters, [], {}).subscribe(
                data => {
                  this.dynamicData[f.form.name] = {
                    "form": f.form,
                    "data": data
                  };
                  // this.filteredDynamicData[obj.select.sourceName]['data'] = data;
                }
              );
          }
        });
      }
    });
  }

  handleActionsClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  getGroupFields(groupName: string) {
    return this.page.getGroupFields(groupName);
  }

  getPropertyList(arr: any[], property: string) {
    return arr.map(f => { return f[property]; });
  }

  getSortedActive(arr: any[]) {
    return arr
      .filter(f => { return f.format != 'hidden'; })
      .sort((a, b) => a.tableSort.toString().localeCompare(b.tableSort.toString(), 'it'));
  }

  getPropertyKV(arr: any[], property: string) {
    let output: FieldsModel[] = [];
    arr.forEach(field => {
      output[field[property]] = field;
    });
    return output;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  isLinkedUsed(linkedObj: LinkedModel, id: number) {
    const relatedIdName = linkedObj.select.idJoin;
    const sourceName = linkedObj.sourceName;
    const searchWhere = this.dynamicLinked[sourceName].map(f => f[relatedIdName]);

    console.log(this.dynamicLinked[sourceName]);
    console.log(linkedObj);
    console.log(id);

    return searchWhere.indexOf(id) > -1;
  }

  addLinked(linked: LinkedModel) {
    const sourceName = linked.sourceName;
    const entityField = linked.idJoin;
    const entityValue = this.data[linked.select?.id];
    const relatedField = linked.select.idJoin;
    const relatedSourceName = linked.select?.sourceName;
    const relatedValues = this.linkedAddEntries[relatedSourceName];

    relatedValues.forEach(relatedValue => {
      var fields = {};
      fields[entityField] = entityValue;
      fields[relatedField] = relatedValue;
      fields = <FieldsModel>fields;

      this.api.insert(null, sourceName, fields).subscribe({
        next: (response) => {
          this.loadData();
          this.linkedAddShow[relatedSourceName] = false;
          // svuota la lista dei selezionati - pulsante "Aggiungi"
          this.linkedAddEntries[relatedSourceName] = null;
          this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
        },
        error: (err) => {
          this.linkedAddShow[relatedSourceName] = false;
          this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail);
        }
      });
    });
  }

  deleteLink(sourceName: string, id: number) {
    if (confirm('Procedere con l\'eliminazione?')) {
      const fields = {
        'active': 0
      };
      const filters: FiltersModel[] = [{
        field: 'id',
        operator: '=',
        value: id
      }];
      this.api.update(null, sourceName, fields, filters).subscribe({
        next: (response) => {
          this.loadData();
          this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
        },
        error: (err) => {
          this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail);
        }
      });
    }
  }

  addLinkedForm(linked: LinkedModel) {
    var error = false;
    var fields = {};
    var sourceName = linked.sourceName;
    var relatedSourceName = linked.sourceName;

    linked.addFields?.forEach(f => {
      // Check obbligatorietà
      if (f.form?.isRequired && !this.linkedAddForm[f.form?.name]?.toString().length) {
        error = true;
      }
      // Check lunghezza massima
      if (f.form?.maxLength && this.linkedAddForm[f.form?.name]?.toString().length > f.form?.maxLength) {
        error = true;
      }

      if (f.form?.name) {
        fields[f.form.name] = this.linkedAddForm[f.form.name];
      }
    });
    if (error) {
      this.notifier.showWarning('Attenzione', 'La form non è compilata correttamente');
      return false;
    }

    fields[linked.idJoin] = this.data['id'];
    fields = <FieldsModel>fields;

    this.api.insert(null, sourceName, fields).subscribe({
      next: (response) => {
        this.loadData();
        this.linkedAddShow[relatedSourceName] = false;
        this.linkedAddForm = [];
        this.notifier.showSuccess('Complimenti', 'Operazione avvenuta correttamente');
        return true;
      },
      error: (err) => {
        this.notifier.showError('Errore', 'Si è verificato un errore: ' + err?.error?.detail);
        return false;
      }
    });

    return false;
  }

  openDialog(_type: ('insert' | 'update'), obj?: typeof this.page.model): void {
    const dialogRef = this.dialog.open(FormModalComponent, {
      data: { model: this.page, formMode: _type, selObj: obj },
    });

    // metodo invocato quando il dialog viene chiuso
    dialogRef.afterClosed().subscribe(result => {
      if (result?.result == 'ok')
        this.loadData();
    });
  }
}
