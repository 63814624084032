import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.services';
import { StorageService } from '../../services/storage.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NotifierService } from 'src/app/services/notifier.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild('username') username: ElementRef;

  form: FormGroup;
  recaptchaSubscription: Subscription;
  showPassword: boolean = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private storage: StorageService,
    private router: Router,
    private notifier: NotifierService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    // redirect to home if already logged in
    if (this.storage.getToken()) {
      this.router.navigate(['/']);
    }
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  ngAfterViewInit() {
    this.username.nativeElement.focus();
    const element = document.getElementsByClassName(
      'grecaptcha-badge'
    )[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  login() {
    const val = this.form.value;

    if (val.username && val.password) {
      this.recaptchaSubscription = this.recaptchaV3Service
        .execute('login')
        .subscribe((token) => {
          this.auth.login(val.username, val.password, token).subscribe({
            next: (data) => {
              // user is logged in
              this.storage.setToken(data);
              this.router.navigate(['/']);
            },
            error: (err) => {
              switch (err.status) {
                case 425:
                  this.notifier.showWarning(
                    'Attenzione',
                    'È stata utilizzare una password temporanea da cambiare al primo accesso'
                  );
                  break;
                case 401:
                  this.notifier.showError(
                    'Attenzione',
                    'Credenziali non valide'
                  );
                  break;
                default:
                  this.notifier.showError(
                    'Attenzione',
                    'Si è verificato un errore: ' +
                      err?.error?.detail +
                      ' - ' +
                      err
                  );
                  break;
              }
            },
          });
        });
    } else {
      this.notifier.showWarning('Attenzione', 'Form non valida');
    }
  }
}
